import React, { useState, useEffect, KeyboardEvent } from 'react';
import { Box, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, useTheme, useMediaQuery, Typography, List, ListItem, ListItemText, Select, MenuItem, InputLabel } from '@mui/material';
import tableConfig, { host } from "./tableConfig";
import { createAxiosWrapper } from "./Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import BatchDialogReadOnly from './Batch/BatchDialogReadOnly';
import ReadOnlyShipment from './Shipping/ReadOnlyShipping';
import { SelectChangeEvent } from '@mui/material/Select';
import ItemDialogPlus from "./ItemDialogPlus";
import {BatchData} from "./types";
import RecallButton from "./RecallButton";

interface Client {
    id: number;
    name: string;
}

interface ShipmentData {
    id: number;
    exp_date: string;
    timestamp: string;
    ticket_num: string;
    products: any[];
    notes: string;
    shipment: number;
}

interface ShipmentInData {
    id: number;
    ingredient: number;
    count: number;
    notes: string;
    lot: string;
    timestamp: string;
    supplier: number;
    price: string;
    invoice_num: string;
    client: number;
    user_name: string;
    deleted: null | boolean;
}

interface SearchResults {
    batches: BatchData[];
    shipments: ShipmentData[];
    shipmentsIn: ShipmentInData[];
}

const Recall: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [clients, setClients] = useState<Client[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [recallType, setRecallType] = useState('ingredientLotCode');
    const [searchValue, setSearchValue] = useState('');
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);
    const [searchResults, setSearchResults] = useState<SearchResults | null>(null);
    const [selectedBatch, setSelectedBatch] = useState<BatchData | null>(null);
    const [selectedShipment, setSelectedShipment] = useState<ShipmentData | null>(null);
    const [selectedIngredient, setSelectedIngredient] = useState<ShipmentInData | null>(null);
    const [batchDialogOpen, setBatchDialogOpen] = useState(false);
    const [shipmentDialogOpen, setShipmentDialogOpen] = useState(false);
    const [ingredientDialogOpen, setIngredientDialogOpen] = useState(false);

/*    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await axiosWrapper({url: `${host}/client`});
            const data = response.data;
            if (data.result === "success" && Array.isArray(data.data)) {
                setClients(data.data);
            } else {
                throw new Error('Failed to fetch clients');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        setSelectedClientId(event.target.value as number);
        setSearchResults(null);
    };*/

    const handleRecallTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecallType(event.target.value);
        setSearchValue('');
        setSearchResults(null);
    };

    const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleSearch = async () => {
/*        if (selectedClientId === '') {
            alert('Please select a client before searching.');
            return;
        }*/

        console.log('Searching with:', { recallType, searchValue, clientId: selectedClientId });
        try {
            const response = await axiosWrapper({
                url: `${host}/lot-search`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    recallType,
                    searchValue,
                    clientId: selectedClientId
                },
            });
            const data = response.data;
            if (data.result === 'success') {
                console.log('result', data);
                setSearchResults(data.data);
            } else {
                throw new Error(data.message || 'Failed to complete search');
            }
        } catch (error) {
            console.error('Error on lot search:', error);
            setSearchResults(null);
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const getInputLabel = () => {
        switch (recallType) {
            case 'ingredientLotCode':
            case 'productLotCode':
                return 'Lot Code';
            case 'productExpirationDate':
                return 'Expiration Date';
            default:
                return 'Search Value';
        }
    };

    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString();
    };

    const handleBatchClick = async (batch: BatchData) => {
        try {
            const response = await axiosWrapper({url: `${host}/get-batch/${recallType === 'ingredientLotCode' ? batch.batch : batch.id}`});
            const data = response.data;
            if (data.result === "success" && data.data) {
                setSelectedBatch(data.data);
                setBatchDialogOpen(true);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    const handleShipmentClick = async (shipment: ShipmentData) => {
        try {
            const response = await axiosWrapper({url: `${host}/shipping/${shipment.shipment || shipment.id}`});
            const data = response.data;
            if (data.result === "success" && data.data) {
                setSelectedShipment(data.data[0]);
                setShipmentDialogOpen(true);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    const handleIngredientClick = async (shipmentIn: ShipmentData) => {
        try {
            const response = await axiosWrapper({url: `${host}/ingredient-receiving/${shipmentIn.id}`});
            const data = response.data;
            if (data.result === "success" && data.data) {
                setSelectedIngredient(data.data);
                setIngredientDialogOpen(true);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    const handleBatchDialogClose = () => {
        setBatchDialogOpen(false);
        setSelectedBatch(null);
    };

    const handleShipmentDialogClose = () => {
        setShipmentDialogOpen(false);
        setSelectedShipment(null);
    };
    const handleIngredientDialogClose = () => {
        setIngredientDialogOpen(false);
        setSelectedIngredient(null);
    };


    const renderResultsSection = (title: string, items: any[], renderItem: (item: any) => React.ReactNode) => {
        if (items.length === 0) {
            return (
                <Box sx={{ mt: 0 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography sx={{pl: 2}}>No {title.toLowerCase()} found</Typography>
                </Box>
            );
        }

        return (
            <Box sx={{ mt: 0, p: 0 }}>
                <Typography variant="h6">{title}</Typography>
                <List sx={{p: 0}}>
                    {items.map(renderItem)}
                </List>
            </Box>
        );
    };

    return (
        <Box sx={{ p: 2, pt: 0, display: 'flex', flexDirection: 'column', gap: 1, mb: 10 }}>
{/*            <FormControl fullWidth>
                <InputLabel id="client-select-label">Client</InputLabel>
                <Select
                    labelId="client-select-label"
                    value={selectedClientId}
                    onChange={handleClientChange}
                    label="Client"
                >
                    {clients.map((client) => (
                        <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>*/}

            <FormControl component="fieldset">
                <FormLabel component="legend">Recall Type</FormLabel>
                <RadioGroup
                    value={recallType}
                    onChange={handleRecallTypeChange}
                >
                    <FormControlLabel value="ingredientLotCode" control={<Radio />} label="Recall by Ingredient Lot Code" />
                    <FormControlLabel value="productLotCode" control={<Radio />} label="Recall by Product Lot Code" />
                    <FormControlLabel value="productExpirationDate" control={<Radio />} label="Recall by Product Expiration Date" />
                </RadioGroup>
            </FormControl>

            <TextField
                label={getInputLabel()}
                variant="outlined"
                value={searchValue}
                onChange={handleSearchValueChange}
                onKeyPress={handleKeyPress}
                fullWidth
                type={recallType === 'productExpirationDate' ? 'date' : 'text'}
                InputLabelProps={recallType === 'productExpirationDate' ? { shrink: true } : {}}
            />

            <Button
                variant="contained"
                onClick={handleSearch}
            >
                Search
            </Button>

            {searchResults && (
                <>
                    {searchResults.batches.length === 0 && searchResults.shipments.length === 0 && searchResults.shipmentsIn.length === 0 ? (
                        <Typography variant="h6" align='center'>No results found</Typography>
                    ) : (
                        <>
                            {renderResultsSection("Batches", searchResults.batches, (batch) => (
                                <ListItem
                                    key={batch.id}
                                    button
                                    onClick={() => handleBatchClick(batch)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'action.hover' }, pb:0, pt:0 }}>
                                    <ListItemText
                                        primary={`${recallType === 'ingredientLotCode' ?batch.batch : batch.id} - ${formatDate(batch.timestamp)}`}
                                        secondary={`${recallType === 'ingredientLotCode' ? 'Ingredient Lot Code ' : 'Lot'}: ${batch.lot}`}
                                        sx={{ color: 'primary.main' }}
                                    />
                                </ListItem>
                            ))}

                            {renderResultsSection("Shipments", searchResults.shipments, (shipment) => (
                                <ListItem
                                    key={shipment.shipment}
                                    button
                                    onClick={() => handleShipmentClick(shipment)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'action.hover'} }}
                                >
                                    <ListItemText
                                        primary={`${shipment.id} - ${formatDate(shipment.timestamp)}`}
                                        sx={{ color: 'primary.main', m:0 }}
                                    />
                                </ListItem>
                            ))}

                            {(recallType === 'ingredientLotCode') && renderResultsSection("Ingredients Receiving", searchResults.shipmentsIn, (shipmentIn) => (
                                <ListItem
                                    key={shipmentIn.id}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'action.hover' } }}
                                    onClick={() => handleIngredientClick(shipmentIn)}
                                >
                                    <ListItemText
                                        primary={`${shipmentIn.id} - ${formatDate(shipmentIn.timestamp)}`}
                                        secondary={`Lot: ${shipmentIn.lot}, Count: ${shipmentIn.count}`}
                                        sx={{ color: 'primary.main', m:0 }}
                                    />
                                </ListItem>
                            ))}
                            {searchResults.batches.length > 0 && (
                                <Box sx={{ mt: 2, width: '100%' }}>
                                    <RecallButton
                                        batchId={recallType === 'ingredientLotCode'
                                            ? (searchResults.batches[0].batch || searchResults.batches[0].id)  // Fallback to id if batch is undefined
                                            : searchResults.batches[0].id
                                        }
                                    />
                                </Box>
                            )}
                        </>

                    )}
                </>
            )}

            {selectedBatch && (
                <BatchDialogReadOnly
                    open={batchDialogOpen}
                    onClose={handleBatchDialogClose}
                    batch={selectedBatch}
                    process={selectedBatch.process}
                />
            )}

            {selectedShipment && (
                <ReadOnlyShipment
                    open={shipmentDialogOpen}
                    onClose={handleShipmentDialogClose}
                    shipment={selectedShipment}
                    showButtons={true}
                    clientName={clients.find(client => client.id === selectedClientId)?.name || ''}
                />
            )}
            {selectedIngredient && (
                <ItemDialogPlus
                    open={ingredientDialogOpen}
                    onClose={handleIngredientDialogClose}
                    item={selectedIngredient}
                    mode={'view'}
                    columns={tableConfig.ingredientReceiving.columns}
                    itemTitle={'Ingredient Receiving'}
                    clientId={selectedClientId}
                    clientName={clients.find(client => client.id === selectedClientId)?.name || ''}
                />
            )}
        </Box>
    );
};

export default Recall;