import React, {useEffect, useRef, useState, forwardRef, useImperativeHandle} from 'react';
import {
    Box,
    TextField,
    Typography,
    Alert,
} from '@mui/material';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import {Dashboard} from '@uppy/react';
import DashboardPlugin from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import {UppyFile} from '@uppy/core';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {host} from "./tableConfig";
import useUserStore from "./store/userStore";

interface BaseFileUploaderProps {
    endpoint: string;
    onUploadComplete: () => void;
    additionalMetadata?: Record<string, any>;
    onError?: (error: string) => void;
    allowedFileTypes: string[];
    fieldName: string;
    thumbnailGenerator?: boolean;
}

export interface FileUploaderRef {
    startUpload: () => Promise<void>;
    reset: () => void;
    setEndpoint: (endpoint: string) => void;
}

type UppyFileType = UppyFile<Record<string, unknown>, Record<string, unknown>>;

interface FileNotes {
    [key: string]: string;
}

const BaseFileUploader = forwardRef<FileUploaderRef, BaseFileUploaderProps>(({
                                                                                 endpoint,
                                                                                 onUploadComplete,
                                                                                 additionalMetadata = {},
                                                                                 onError,
                                                                                 allowedFileTypes,
                                                                                 fieldName,
                                                                                 thumbnailGenerator = false,
                                                                             }, ref) => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [fileNotes, setFileNotes] = useState<FileNotes>({});
    const [isInitialized, setIsInitialized] = useState(false);
    const uppyInstance = useRef<Uppy | null>(null);

    const {getToken} = useKindeAuth();
    const {user} = useUserStore();

    const initUppy = async () => {
        if (!getToken) {
            console.error('getToken is not available');
            return false;
        }

        try {
            const token = await getToken();

            if (!uppyInstance.current) {
                uppyInstance.current = new Uppy({
                    restrictions: {
                        allowedFileTypes: allowedFileTypes
                    },
                    autoProceed: false,
                })
                    .use(Webcam, {
                        mirror: true,
                        facingMode: 'environment',
                        showRecordingLength: true,
                        modes: ['picture'],
                    });

                // Conditionally add ThumbnailGenerator
                if (thumbnailGenerator) {
                    uppyInstance.current.use(ThumbnailGenerator, {
                        thumbnailWidth: 200,
                        thumbnailHeight: 200,
                        waitForThumbnailsBeforeUpload: true,
                    });
                }

                uppyInstance.current
                    .use(DashboardPlugin, {
                        inline: true,
                        target: '.uppyDashboard',
                        showProgressDetails: true,
                        height: 300,
                        width: '100%',
                        hideCancelButton: true,
                        hideUploadButton: true,
                        hideRetryButton: true,
                        hidePauseResumeButton: true,
                        showRemoveButtonAfterComplete: true,
                        proudlyDisplayPoweredByUppy: false,
                        plugins: ['Webcam'],
                    })
                    .use(XHRUpload, {
                        endpoint: endpoint,
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        formData: true,
                        fieldName: fieldName
                    });

                const uppy = uppyInstance.current;

                uppy.on('upload', () => {
                    setIsUploading(true);
                    setUploadError(null);
                });

                uppy.on('file-added', (file: UppyFileType) => {
                    setFileNotes(prev => ({
                        ...prev,
                        [file.id]: ''
                    }));
                });

                uppy.on('file-removed', (file: UppyFileType) => {
                    setFileNotes(prev => {
                        const newNotes = {...prev};
                        delete newNotes[file.id];
                        return newNotes;
                    });
                });

                uppy.on('upload-success', (file: UppyFileType) => {
                    setIsUploading(false);
                });

                uppy.on('upload-error', (file: UppyFileType, error: any) => {
                    setIsUploading(false);
                    const errorMessage = error.message || 'Upload failed';
                    setUploadError(errorMessage);
                    if (onError) {
                        onError(errorMessage);
                    }
                });

                uppy.on('complete', (result: any) => {
                    if (result.failed.length > 0) {
                        const errorMessage = 'Some uploads failed. Please try again.';
                        setUploadError(errorMessage);
                        if (onError) {
                            onError(errorMessage);
                        }
                    } else if (result.successful.length > 0) {
                        onUploadComplete();
                    }
                    setIsUploading(false);
                });

                setIsInitialized(true);
                return true;
            }
            return true;
        } catch (error) {
            const errorMessage = 'Failed to initialize upload. Please try again.';
            console.error('Error initializing Uppy:', error);
            setUploadError(errorMessage);
            if (onError) {
                onError(errorMessage);
            }
            return false;
        }
    };

    useEffect(() => {
        initUppy();

        return () => {
            if (uppyInstance.current) {
                try {
                    const files = uppyInstance.current.getFiles();
                    files.forEach((file: UppyFileType) => {
                        uppyInstance.current?.removeFile(file.id);
                    });

                    uppyInstance.current.off('upload');
                    uppyInstance.current.off('file-added');
                    uppyInstance.current.off('file-removed');
                    uppyInstance.current.off('upload-success');
                    uppyInstance.current.off('upload-error');
                    uppyInstance.current.off('complete');

                    const dashboard = uppyInstance.current.getPlugin('Dashboard');
                    if (dashboard) {
                        dashboard.unmount();
                    }

                    uppyInstance.current.destroy();
                    uppyInstance.current = null;
                    setIsInitialized(false);
                } catch (error) {
                    console.error('Error during Uppy cleanup:', error);
                }
            }
            setFileNotes({});
        };
    }, [endpoint]);

    useImperativeHandle(ref, () => ({
        startUpload: async () => {
            if (!isInitialized) {
                const initialized = await initUppy();
                if (!initialized) {
                    throw new Error('Failed to initialize uploader');
                }
            }

            if (!uppyInstance.current || !getToken) {
                const errorMessage = 'Upload is not available';
                setUploadError(errorMessage);
                if (onError) {
                    onError(errorMessage);
                }
                throw new Error(errorMessage);
            }

            try {
                const token = await getToken();
                const uppy = uppyInstance.current;
                const files = uppy.getFiles();

                if (files.length === 0) {
                    const errorMessage = 'No files selected';
                    setUploadError(errorMessage);
                    if (onError) {
                        onError(errorMessage);
                    }
                    throw new Error(errorMessage);
                }

                const xhrUpload = uppy.getPlugin('XHRUpload');
                if (xhrUpload) {
                    xhrUpload.setOptions({
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        formData: true,
                        fieldName: fieldName,
                        metaFields: ['notes', 'clientId', 'albumId'],
                    });
                }

                files.forEach((file: any) => {
                    uppy.setFileMeta(file.id, {
                        ...additionalMetadata,
                        notes: fileNotes[file.id] || '',
                        clientId: additionalMetadata["clientId"]
                    });
                });

                uppy.upload();
            } catch (error) {
                const errorMessage = 'Failed to start upload. Please try again.';
                console.error('Error starting upload:', error);
                setUploadError(errorMessage);
                if (onError) {
                    onError(errorMessage);
                }
                throw error;
            }
        },
        reset: () => {
            if (uppyInstance.current) {
                const files = uppyInstance.current.getFiles();
                files.forEach((file: any) => {
                    uppyInstance.current?.removeFile(file.id);
                });
            }
            setFileNotes({});
            setUploadError(null);
        },
        setEndpoint: (newEndpoint: string) => {
            if (uppyInstance.current) {
                const xhrUpload = uppyInstance.current.getPlugin('XHRUpload');
                if (xhrUpload) {
                    xhrUpload.setOptions({
                        endpoint: newEndpoint
                    });
                }
            }
        }
    }));

    const handleNoteChange = (fileId: string, note: string) => {
        setFileNotes(prev => ({
            ...prev,
            [fileId]: note
        }));
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            {uploadError && (
                <Alert severity="error" onClose={() => setUploadError(null)}>
                    {uploadError}
                </Alert>
            )}
            <div className="uppyDashboard"/>
            {/*{uppyInstance.current && Object.entries(fileNotes).map(([fileId, note]) => {
                const file = uppyInstance.current?.getFile(fileId);
                if (!file) return null;

                return (
                    <Box key={fileId}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Notes for {file.name}:
                        </Typography>
                        <TextField
                            multiline
                            rows={2}
                            value={note}
                            onChange={(e) => handleNoteChange(fileId, e.target.value)}
                            fullWidth
                            placeholder={`Add notes for ${file.name}`}
                        />
                    </Box>
                );
            })}*/}
        </Box>
    );
});

export default BaseFileUploader;