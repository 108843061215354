import React, { useState, useEffect, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    TableSortLabel,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography, Paper
} from '@mui/material';
import AddScheduledProcessDialog from './AddScheduledProcessDialog';
import EditScheduledProcessDialog from './EditScheduledProcessDialog';
import { ScheduledProcess } from '../types';
import { host } from '../tableConfig'
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import useUserStore from '../store/userStore';

interface ScheduledProcessTableProps {}

type Order = 'asc' | 'desc';

const ScheduledProcessTable: React.FC<ScheduledProcessTableProps> = () => {
    const [processes, setProcesses] = useState<ScheduledProcess[]>([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedProcessId, setSelectedProcessId] = useState<number>(0);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof ScheduledProcess>('name');
    const { getToken } = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedClientName, setSelectedClientName] = useState<string>('');

    // Get user and clients from the store
    const { user } = useUserStore();

    // Memoize the clients array
    const clients = useMemo(() => user?.clients || [], [user?.clients]);

    // Determine if we're dealing with a single client
    const isSingleClient = useMemo(() => clients.length <= 1, [clients]);

    useEffect(() => {
        if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId);
            setSelectedClientName(clients[0]?.name || user?.organization || '');
        }
    }, [isSingleClient, user?.clientId, clients, user?.organization]);

    useEffect(() => {
        if (selectedClientId) {
            fetchProcesses();
        }
    }, [selectedClientId]);

    const fetchProcesses = async () => {
        if (!selectedClientId) return;
        try {
            const response = await axiosWrapper({ url: `${host}/scheduled-process?clientId=${selectedClientId}` });
            const data = response.data;
            if (data.result === 'success') {
                setProcesses(data.data);
            }
        } catch (error) {
            console.error('Error fetching processes:', error);
        }
    };

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        const selectedClient = clients.find(client => client.id === clientId);
        setSelectedClientName(selectedClient ? selectedClient.name : '');
    };

    const handleAddProcess = () => {
        setOpenAddDialog(true);
    };

    const handleEditProcess = (id: number) => {
        setSelectedProcessId(id);
        setOpenEditDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleSave = async (newProcess: Omit<ScheduledProcess, 'id' | 'clientName'>) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/scheduled-process`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: { ...newProcess, clientId: selectedClientId },
            });
            const data = response.data;
            if (data.result === 'success') {
                await fetchProcesses();
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error saving process:', error);
            return false;
        }
    };

    const handleUpdate = async (updatedProcess: ScheduledProcess) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/scheduled-process/${updatedProcess.id}`,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: updatedProcess,
            });
            const data = response.data;
            if (data.result === 'success') {
                await fetchProcesses();
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating process:', error);
            return false;
        }
    };

    const handleRequestSort = (property: keyof ScheduledProcess) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedProcesses = React.useMemo(() => {
        const comparator = (a: ScheduledProcess, b: ScheduledProcess) => {
            if (!a.hasOwnProperty(orderBy) || !b.hasOwnProperty(orderBy)) {
                return 0;
            }

            const aValue = a[orderBy];
            const bValue = b[orderBy];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return order === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }

            if (aValue !== undefined && bValue !== undefined) {
                if (aValue < bValue) {
                    return order === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return order === 'asc' ? 1 : -1;
                }
            }
            return 0;
        };

        return [...processes].sort(comparator);
    }, [processes, order, orderBy]);

    // Memoize the client menu items
    const clientMenuItems = useMemo(() =>
            clients.map((client:any) => (
                <MenuItem key={client.id} value={client.id}>
                    {client.name}
                </MenuItem>
            )),
        [clients]);

    return (
        <Box sx={{paddingLeft: '12px', paddingRight: '12px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '20px', marginBottom: '20px'}}>
                {!isSingleClient ? (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="client-select-label">Client</InputLabel>
                        <Select
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClientId}
                            label="Client"
                            sx={{ width: '250px' }}
                            onChange={handleClientChange}
                        >
                            {clientMenuItems}
                        </Select>
                    </FormControl>
                ) : (
                    selectedClientName && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Client: {selectedClientName}
                        </Typography>
                    )
                )}
                {selectedClientId && (
                    <Button variant="contained" color="primary" onClick={handleAddProcess}>
                        Add Scheduled Process
                    </Button>
                )}
            </Box>
            {selectedClientId && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'name'}
                                        direction={orderBy === 'name' ? order : 'asc'}
                                        onClick={() => handleRequestSort('name')}>
                                        <strong>Process Name</strong>
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedProcesses.map((process) => (
                                <TableRow sx={{cursor:'pointer'}} key={process.id} onClick={() => handleEditProcess(process.id!)}>
                                    <TableCell>{process.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <AddScheduledProcessDialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                onSave={handleSave}
                clientName={selectedClientName}
                clientId={selectedClientId || ''}
            />
            {selectedProcessId !== null && (
                <EditScheduledProcessDialog
                    open={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onSave={handleUpdate}
                    processId={selectedProcessId}
                    clientName={selectedClientName}
                />
            )}
        </Box>
    );
};

export default ScheduledProcessTable;