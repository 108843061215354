import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Typography,
    Box,
    CircularProgress,
    Select,
    MenuItem,
    InputLabel,
    SelectChangeEvent, useTheme, useMediaQuery
} from '@mui/material';
import { Column } from './TablePanel';
import {createAxiosWrapper} from "./Util";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";

interface Item {
    id: number;
    [key: string]: any;
}

interface ItemDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (item: Omit<Item, 'id'>) => Promise<void>;
    onDelete: (item: Omit<Item, 'id'>) => Promise<void>;
    item?: Item;
    mode: 'add' | 'view' | 'edit';
    onModeChange: (newMode: 'add' | 'view' | 'edit') => void;
    columns: readonly Column[];
    itemTitle: string;
}

interface SelectOption {
    id: number | string;
    [key: string]: any;
}

function nullToEmptyStringInPlace(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null) {
            obj[key] = '';
        }
    });
    return obj;
}

const ItemDialog: React.FC<ItemDialogProps> = ({ open, onClose, onSave, onDelete, item, mode, onModeChange, columns, itemTitle }) => {
    const [formData, setFormData] = useState<Omit<Item, 'id'>>({});
    const [isSaving, setIsSaving] = useState(false);
    const [selectOptions, setSelectOptions] = useState<{ [key: string]: SelectOption[] }>({});
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (item) {
            const cleanedItem = nullToEmptyStringInPlace({ ...item });
            setFormData(cleanedItem);
        } else {
            const newFormData: { [key: string]: any } = {};
            columns.forEach(column => {
                newFormData[column.id] = column.type === 'radio' ? (column.options?.[0] || '') : '';
            });
            setFormData(newFormData);
        }

        // Fetch options for select inputs
        columns.forEach(column => {
            if (column.type === 'select' && column.endpoint) {
                fetchSelectOptions(column.id, column.endpoint);
            }
        });
    }, [item, open, columns]);

    const fetchSelectOptions = async (columnId: string, endpoint: string) => {
        try {
            const response = await axiosWrapper({url: endpoint});
            if (response.data.result === 'success' && Array.isArray(response.data.data)) {
                setSelectOptions(prev => ({
                    ...prev,
                    [columnId]: response.data.data,
                }));
            }
        } catch (error) {
            console.error(`Error fetching options for ${columnId}:`, error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name as string]: value }));
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await onSave(formData);
            onModeChange('view');
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
        } finally {
            setIsSaving(false);
        }
    };
    const handleDelete = async () => {
        setIsSaving(true);
        try {
            await onDelete(formData);
            onModeChange('view');
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleEdit = () => {
        onModeChange('edit');
    };

    const isViewMode = mode === 'view';

    const renderField = (column: Column, value: string | number) => {
        let displayValue: string | number = value;

        if (value === '' || value === undefined || value === null) {
            displayValue = 'Not set';
        } else if (column.type === 'radio' && column.options) {
            displayValue = column.options[Number(value)] || value;
        } else if (column.type === 'select' && selectOptions[column.id]) {
            const option = selectOptions[column.id].find(opt => opt.id.toString() === value.toString());
            displayValue = option ? option.brand_name || option.name || option.title || value : value;
        }

        return (
            <Box key={column.id} mb={2}>
                <Typography variant="h5">{column.label}</Typography>
                <Typography variant="body1">{displayValue} </Typography>
            </Box>
        );
    };

    const renderInput = (column: Column) => {
        if (column.type === 'radio' && column.options) {
            return (
                <FormControl component="fieldset" margin="normal" key={column.id}>
                    <FormLabel component="legend">{column.label}</FormLabel>
                    <RadioGroup
                        aria-label={column.id}
                        name={column.id}
                        value={formData[column.id]?.toString() || ''}
                        onChange={handleRadioChange}
                    >
                        {column.options.map((option: any, index: any) => (
                            <FormControlLabel key={option} value={index.toString()} control={<Radio />} label={option} />
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        } else if (column.type === 'select') {
            const currentValue = formData[column.id];
            const options = selectOptions[column.id] || [];
            const hasMatchingOption = options.some(opt => opt.id.toString() === currentValue?.toString());

            return (
                <FormControl fullWidth margin="normal" key={column.id}>
                    <InputLabel id={`${column.id}-label`}>{column.label}</InputLabel>
                    <Select
                        labelId={`${column.id}-label`}
                        id={column.id}
                        name={column.id}
                        value={hasMatchingOption ? currentValue : ''}
                        onChange={handleSelectChange}
                        label={column.label}
                    >
                        {!hasMatchingOption && currentValue && (
                            <MenuItem value={currentValue}>
                                {currentValue} (Current Value)
                            </MenuItem>
                        )}
                        {options.map((option: SelectOption) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.brand_name || option.name || option.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else {
            return (
                <TextField
                    key={column.id}
                    name={column.id}
                    label={column.label}
                    value={formData[column.id] || ''}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    multiline={column.rows !== undefined}
                    rows={column.rows}
                />
            );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={fullScreen}
            PaperProps={{
                sx: {
                    m: fullScreen ? 0 : 2,
                    height: fullScreen ? '100%' : 'auto',
                    maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)'
                }
            }}
        >
            <DialogTitle>{isViewMode ? `${itemTitle} Details` : (mode === 'edit' ? `Edit ${itemTitle}` : `Add ${itemTitle}`)}</DialogTitle>
            <DialogContent>
                {isViewMode ? (
                    <>
                        {columns.map(column => renderField(column, formData[column.id]))}
                    </>
                ) : (
                    <>
                        {columns.map(column => renderInput(column))}
                    </>
                )}
            </DialogContent>
            <DialogActions sx={{margin: '20px'}}>
                {isViewMode ? (
                    <>
                        <Button variant="contained" color="secondary" onClick={onClose}>Close</Button>
                        <Button variant="contained" onClick={handleEdit}>Edit</Button>
                    </>
                ) : (
                    <>
                        <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
                        <Button variant="contained" onClick={handleDelete} disabled={isSaving}>
                            {isSaving ? <CircularProgress size={24} /> : 'Delete'}
                        </Button>
                        <Button variant="contained" onClick={handleSave} disabled={isSaving}>
                            {isSaving ? <CircularProgress size={24} /> : 'Save'}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ItemDialog;