import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, Grid, Paper } from '@mui/material';
import { Close, Delete } from "@mui/icons-material";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import { host } from "../tableConfig";
import useUserStore from "../store/userStore";

interface AlbumDialogProps {
    open: boolean;
    onClose: () => void;
    albumId: string;
    albumName: string;
}

interface CustomPhoto {
    id: string;
    name: string;
    notes: string;
    src: string;
}

const AlbumDialog: React.FC<AlbumDialogProps> = ({ open, onClose, albumId, albumName }) => {
    const [photos, setPhotos] = useState<CustomPhoto[]>([]);
    const [fullscreenPhoto, setFullscreenPhoto] = useState<CustomPhoto | null>(null);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);
    const { user } = useUserStore();

    useEffect(() => {
        if (open && albumId) {
            fetchPhotos();
        }
    }, [open, albumId]);

    const fetchPhotos = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/getSQLResults/list_photos/${albumId}?clientId=${user?.clientId}` });
            const data = response.data;
            if (data.result === 'success') {
                const processedPhotos = data.data.map((photo: any) => ({
                    id: photo.id,
                    name: photo.name,
                    notes: photo.notes,
                    src: getPhotoUrl(photo.name, true),
                }));
                setPhotos(processedPhotos);
            }
        } catch (error) {
            console.error('Error fetching photos:', error);
        }
    };

    const handleDeletePhoto = async (photoId: string) => {
        if (window.confirm('Are you sure you want to delete this photo?')) {
            try {
                const response = await axiosWrapper({
                    url: `${host}/delete-photo/${photoId}?clientId=${user?.clientId}`,
                    method: 'DELETE'
                });

                if (response.data.result === 'success') {
                    setPhotos(photos.filter(photo => photo.id !== photoId));
                }
            } catch (error) {
                console.error('Error deleting photo:', error);
            }
        }
    };

    const getPhotoUrl = (name: string, isSmall: boolean = true) => {
        const parts = name.split('-');
        const newName = isSmall
            ? `${parts[0]}-sm-${parts.slice(1).join('-')}`
            : name;
        return `https://saltroad-gmp-photos.s3.amazonaws.com/uploads/${user?.clientId}/${newName}`;
    };

    const handlePhotoClick = (photo: CustomPhoto) => {
        setFullscreenPhoto(photo);
    };

    const CustomPhotoComponent: React.FC<{ photo: CustomPhoto }> = ({ photo }) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    position: 'relative',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Box
                    sx={{
                        position: 'relative',
                        paddingTop: '75%', // 4:3 Aspect Ratio
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {isHovered && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeletePhoto(photo.id);
                            }}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: 2,
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                }
                            }}
                            size="small"
                        >
                            <Delete />
                        </IconButton>
                    )}
                    <img
                        src={photo.src}
                        alt={photo.name}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                        }}
                        onClick={() => handlePhotoClick(photo)}
                    />
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        padding: '8px',
                        flexGrow: 1,
                        minHeight: '60px',
                        maxHeight: '100px',
                        overflowY: 'auto',
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            color: 'black',
                            textAlign: 'left',
                            display: 'block',
                        }}
                    >
                        {photo.notes}
                    </Typography>
                </Box>
            </Paper>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {albumName}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    {photos.map((photo) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={photo.id} sx={{ display: 'flex' }}>
                            <CustomPhotoComponent photo={photo} />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            {fullscreenPhoto && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                    onClick={() => setFullscreenPhoto(null)}
                >
                    <Box
                        sx={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'white',
                            borderRadius: '4px',
                            overflow: 'hidden',
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            src={getPhotoUrl(fullscreenPhoto.name, false)}
                            alt="Full size"
                            style={{ maxWidth: '100%', maxHeight: 'calc(90vh - 100px)', objectFit: 'contain' }}
                        />
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                padding: '16px',
                                maxHeight: '100px',
                                overflowY: 'auto',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'black',
                                    textAlign: 'left',
                                }}
                            >
                                {fullscreenPhoto.notes}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </Dialog>
    );
};

export default AlbumDialog;