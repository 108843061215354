import React, { useState } from 'react';
import { Typography, TextField, Select, MenuItem, Button } from '@mui/material';

interface Note {
    id?: number;
    notes: string;
    status: string;
    timestamp?: string;
    user_name?: string;
    fkey?: number;
    fkey_table?: string;
}

interface NotesSectionProps {
    notes: Note[];
    onNotesChange?: (notes: Note[]) => void;
    isViewMode: boolean;
}

const StatusSquare = ({ color }: { color: string }) => (
    <span style={{
        color: color,
        fontSize: '24px',
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        minWidth: '24px'
    }}>
    ■
  </span>
);

const NotesSection: React.FC<NotesSectionProps> = ({ notes, onNotesChange, isViewMode }) => {
    const [isAddingNote, setIsAddingNote] = useState(false);

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'R': return '#ef4444';
            case 'Y': return '#eab308';
            case 'G': return '#22c55e';
            default: return '#gray';
        }
    };

    const handleAddNote = () => {
        if (onNotesChange) {
            // Ensure we're creating a new note without an ID
            const newNote: Note = {
                notes: '',
                status: 'G'
                // No id field - this indicates it's new to the backend
            };
            onNotesChange([...notes, newNote]);
            setIsAddingNote(true);
        }
    };

    const handleNoteChange = (index: number, field: 'notes' | 'status', value: string) => {
        if (onNotesChange) {
            const updatedNotes = notes.map((note, i) => {
                if (i === index) {
                    // Preserve the id and other fields when updating
                    return {
                        ...note,
                        [field]: value
                    };
                }
                return note;
            });
            onNotesChange(updatedNotes);
        }
    };

    const formatDate = (timestamp: string) => {
        try {
            const date = new Date(timestamp);
            return date.toLocaleDateString('en-US', {
                month: 'numeric',
                day: 'numeric',
                year: '2-digit'
            });
        } catch (e) {
            return timestamp;
        }
    };

    // Get all display notes (everything except the currently editing note)
    const displayNotes = isAddingNote ? notes.slice(0, -1) : notes;
    const currentEditableNote = isAddingNote ? notes[notes.length - 1] : null;

    return (
        <div className="mt-6">
            {notes.length > 0 && <Typography variant="h6" sx={{ mb: 2 }}>Updates</Typography>}

            {/* All Notes (read-only) */}
            <div className="space-y-4 mb-4">
                {displayNotes.map((note) => (
                    <div key={note.id || `temp-${Math.random()}`} className="rounded-lg">
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'auto 1fr auto auto',
                            alignItems: 'center',
                            gap: '20px',
                            backgroundColor: '#fafafa',
                            padding: '8px 12px',
                            borderBottom: '1px solid #eee'
                        }}>
                            <StatusSquare color={getStatusColor(note.status)} />
                            <div />
                            <span style={{ color: '#666', fontSize: '0.875rem' }}>{note.user_name}</span>
                            <span style={{ color: '#666', fontSize: '0.875rem', minWidth: '60px' }}>
                                {note.timestamp ? formatDate(note.timestamp) : 'New'}
                            </span>
                        </div>
                        <div style={{ padding: '12px', backgroundColor: 'white' }}>
                            {note.notes}
                        </div>
                    </div>
                ))}
            </div>

            {/* Editable Note */}
            {!isViewMode && (
                <div className="space-y-3 mb-4">
                    {currentEditableNote && (
                        <div className="flex gap-3">
                            <TextField
                                multiline
                                rows={3}
                                value={currentEditableNote.notes}
                                onChange={(e) => handleNoteChange(notes.length - 1, 'notes', e.target.value)}
                                placeholder="Enter update..."
                                fullWidth
                                size="small"
                                autoFocus
                            />
                            <Select
                                value={currentEditableNote.status}
                                onChange={(e) => handleNoteChange(notes.length - 1, 'status', e.target.value)}
                                size="small"
                                sx={{
                                    width: 65,
                                    my: 1,
                                    '& .MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="R">
                                    <StatusSquare color="#ef4444" />
                                </MenuItem>
                                <MenuItem value="Y">
                                    <StatusSquare color="#eab308" />
                                </MenuItem>
                                <MenuItem value="G">
                                    <StatusSquare color="#22c55e" />
                                </MenuItem>
                            </Select>
                        </div>
                    )}

                    {!isAddingNote && (
                        <Button
                            onClick={handleAddNote}
                            variant="contained"
                            size="small"
                        >
                            Add Update
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotesSection;