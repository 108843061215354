import React, { useEffect, useState } from 'react';
import {
    StreamChat,
    Channel as StreamChannel,
    ChannelSort,
} from 'stream-chat';
import {
    ChannelList,
    ChannelPreviewUIComponentProps,
    DefaultStreamChatGenerics
} from 'stream-chat-react';
import {
    Box,
    CircularProgress,
    ListItem,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Hash as HashIcon, MessageCircle as MessageCircleIcon } from 'lucide-react';

type CustomChannelPreviewProps = ChannelPreviewUIComponentProps & {
    onMobileChannelClick?: () => void;
};

const CustomChannelPreview = (props: CustomChannelPreviewProps) => {
    const { channel, setActiveChannel, active } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!channel) return null;

    const isMainChannel = channel.id === 'general';
    const members = Object.values(channel.state?.members || {});
    const otherMember = members.find(member => member?.user?.id !== channel._client.userID);
    const hasUnread = channel.state.unreadCount > 0;

    const handleChannelClick = () => {
        if (setActiveChannel) {
            setActiveChannel(channel);
            if (isMobile) {
                props.onMobileChannelClick?.();
            }
        }
    };

    return (
        <ListItem
            button
            onClick={handleChannelClick}
            sx={{
                px: 2,
                py: 1,
                backgroundColor: active ? 'rgba(147, 51, 234, 0.08)' : 'transparent',
                '&:hover': {
                    backgroundColor: active
                        ? 'rgba(147, 51, 234, 0.12)'
                        : 'rgba(0, 0, 0, 0.04)',
                },
                borderRadius: '4px',
                mx: 1,
                mb: 0.5,
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                minWidth: 0, // Prevent horizontal scroll
            }}
        >
            {isMainChannel ? (
                <HashIcon size={18} className={hasUnread ? 'text-purple-600' : 'text-gray-400'} />
            ) : (
                <MessageCircleIcon size={18} className={hasUnread ? 'text-purple-600' : 'text-gray-400'} />
            )}
            <ListItemText
                primary={
                    <Typography
                        variant="body2"
                        noWrap // Prevent text wrapping
                        sx={{
                            fontWeight: hasUnread ? 600 : 400,
                            color: hasUnread ? 'text.primary' : 'text.secondary',
                            fontSize: '0.95rem',
                        }}
                    >
                        {isMainChannel ? 'general' : otherMember?.user?.name || 'Direct Message'}
                    </Typography>
                }
            />
            {hasUnread && (
                <Box
                    sx={{
                        backgroundColor: 'purple.500',
                        color: 'white',
                        borderRadius: '10px',
                        px: 1,
                        py: 0.25,
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        minWidth: '20px',
                        textAlign: 'center',
                        flexShrink: 0, // Prevent badge from shrinking
                    }}
                >
                    {channel.state.unreadCount}
                </Box>
            )}
        </ListItem>
    );
};

interface ChannelListWrapperProps {
    client: StreamChat;
    onMobileChannelClick: () => void;
}

export const ChannelListWrapper = ({
                                       client,
                                       onMobileChannelClick
                                   }: ChannelListWrapperProps) => {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (client?.userID) {
            setIsReady(true);
        }
    }, [client]);

    if (!isReady || !client.userID) {
        return (
            <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                pt: 2,
                // Override Stream Chat's default styles to prevent horizontal scroll
                '.str-chat-channel-list': {
                    overflowX: 'hidden !important'
                },
                '.str-chat-channel-list-react': {
                    overflowX: 'hidden !important'
                },
                '.str-chat__channel-list-messenger__main': {
                    overflowX: 'hidden !important'
                }
            }}
        >
            <Typography
                variant="overline"
                sx={{
                    px: 3,
                    py: 1,
                    color: 'text.secondary',
                    fontWeight: 600,
                    letterSpacing: '0.1em',
                    fontSize: '0.75rem',
                }}
            >
                Channels
            </Typography>
            <Box sx={{ overflowX: 'hidden' }}>
                <ChannelList
                    filters={{
                        id: 'general',
                        members: { $in: [client.userID] }
                    }}
                    sort={{ last_message_at: -1 }}
                    Preview={(previewProps) => (
                        <CustomChannelPreview
                            {...previewProps}
                            onMobileChannelClick={onMobileChannelClick}
                        />
                    )}
                    options={{
                        state: true,
                        watch: true,
                        presence: true,
                    }}
                />
                <ChannelList
                    filters={{
                        id: { $ne: 'general' },
                        members: { $in: [client.userID] }
                    }}
                    sort={{ last_message_at: -1 }}
                    Preview={(previewProps) => (
                        <CustomChannelPreview
                            {...previewProps}
                            onMobileChannelClick={onMobileChannelClick}
                        />
                    )}
                    options={{
                        state: true,
                        watch: true,
                        presence: true,
                        limit: 30
                    }}
                />
            </Box>
        </Box>
    );
};

export default ChannelListWrapper;