// ReadOnlyScheduledProcess.tsx
import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@mui/material';
import { ScheduledProcess, IngredientOption, PackagingOption, ProductOption, MetricOption } from '../types';
import ReadOnlyScheduledProcessPanel from './ReadOnlyScheduledProcessPanel';

interface ReadOnlyScheduledProcessProps {
    open: boolean;
    onClose?: () => void;
    onEdit?: () => void;
    process: ScheduledProcess;
    qualityParams: string;
    setup: string;
    packagingSpecs: string;
    ingredientList: IngredientOption[];
    packagingList: PackagingOption[];
    productList: ProductOption[];
    clients: { id: number; name: string; }[];
    metrics: MetricOption[];
    showButtons: boolean;
    photos?: Array<{ id: number; url: string; name: string; }>;
}

const ReadOnlyScheduledProcess: React.FC<ReadOnlyScheduledProcessProps> = ({
                                                                               open,
                                                                               onClose,
                                                                               onEdit,
                                                                               process,
                                                                               ingredientList,
                                                                               packagingList,
                                                                               productList,
                                                                               clients,
                                                                               metrics,
                                                                               showButtons,
                                                                               photos = []
                                                                           }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    margin: 0,
                    width: '100%',
                    maxHeight: '100%'
                },
            }}
        >
            <DialogTitle sx={{ marginBottom: '16px', fontSize: '1.5rem' }}>Scheduled Process Details</DialogTitle>
            <DialogContent>
                <ReadOnlyScheduledProcessPanel
                    process={process}
                    photos={photos}
                />
            </DialogContent>
            {showButtons && (
                <DialogActions sx={{marginRight: '20px', marginBottom: '20px'}}>
                    <Button variant="contained" color="secondary" onClick={onClose}>Close</Button>
                    <Button variant="contained" onClick={onEdit}>Edit</Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ReadOnlyScheduledProcess;