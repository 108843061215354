import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    IconButton,
    CircularProgress
} from '@mui/material';
import { Close as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import { createAxiosWrapper, getPriceAsNumber } from "../Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { formatDate } from "../Util";
import { BatchData } from "../types";
import BatchHeader from "./BatchHeader";
import BatchDetailsForm from "./BatchDetailsForm";
import MultiPhotoUploader, { MultiPhotoUploaderRef } from '../MultiPhotoUploader';
import { host } from "../tableConfig";
import dayjs from 'dayjs';
import { FormData, Ingredient, Product } from './batchTypes';

interface BatchDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (batch: BatchData, isDone: boolean) => void;
    batch: BatchData;
    process: number | '';
    clientId: any
}

const BatchDialog = ({
                         open,
                         onClose,
                         onSave,
                         batch,
                         process,
                         clientId
                     }: BatchDialogProps): JSX.Element => {
    const initialFormData: FormData = {
        outputNotes: batch.output_notes || '',
        laborNotes: batch.labor_notes || '',
        laborHours: batch.labor_hours || '',
        count: batch.count || '',
        lotCode: batch.lot || '',
        expDate: batch.exp_date ? dayjs(batch.exp_date) : null
    };

    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [processData, setProcessData] = useState<any>({});
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    // New state for photos
    const [photos, setPhotos] = useState<any[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [previewImage, setPreviewImage] = useState<{ url: string; name: string } | null>(null);
    const uploaderRef = useRef<MultiPhotoUploaderRef>(null);

    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        setFormData({
            outputNotes: batch.output_notes || '',
            laborNotes: batch.labor_notes || '',
            laborHours: batch.labor_hours || '',
            count: batch.count || '',
            lotCode: batch.lot || '',
            expDate: batch.exp_date ? dayjs(batch.exp_date) : null
        });
    }, [batch]);

    useEffect(() => {
        fetchProcess();
        fetchBatchPhotos();
    }, []);

    const fetchProcess = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/scheduled-process/${process}` });
            const photos = await axiosWrapper({ url: `${host}/getSqlResults/list_process_photos?filter=${process}` });
            const data = response.data;
            if (data.result === 'success') {
                const processData = data.data[0];
                processData.photos = photos.data.data;
                setProcessData(processData);
                const fetchedIngredients = data.data[0].ingredients.map((ingredient: any) => {
                    const batchIngredient = batch.ingredients?.find((i:any) => i.ingredient === ingredient.id);
                    return {
                        id: ingredient.id,
                        name: ingredient.name,
                        lot: batchIngredient?.lot || '',
                        price: getPriceAsNumber(batchIngredient?.price || ingredient.ref_price),
                        metricName: ingredient.metricName,
                        ref_metricName: ingredient.ref_metricName,
                    };
                });
                setIngredients(fetchedIngredients);

                const fetchedProducts = data.data[0].products.map((product: any) => {
                    const batchProduct = batch.products?.find((p:any) => p.product === product.id);
                    const tollValue = batchProduct?.toll ?? '0';
                    return {
                        id: product.id,
                        name: product.name,
                        count: batchProduct?.count || '',
                        toll: typeof tollValue === 'string' ?
                            parseFloat(tollValue.replace('$', '')) :
                            tollValue
                    };
                });
                setProducts(fetchedProducts);
            }
        } catch (error) {
            console.error('Error fetching processes:', error);
        }
    };

    // New functions for photo handling
    const fetchBatchPhotos = async () => {
        try {
            const response = await axiosWrapper({
                url: `${host}/getSqlResults/list_batch_photos?filter=${batch.id}`
            });
            const data = response.data;
            if (data.result === 'success') {
                setPhotos(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching batch photos:', error);
        }
    };

    const handleDeletePhoto = async (photoId: number) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/batch-photos/${photoId}`,
                method: 'DELETE'
            });

            if (response.data.result === 'success') {
                fetchBatchPhotos();
            } else {
                console.error('Failed to delete photo:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    };

    const handleStartUpload = async () => {
        if (uploaderRef.current) {
            setIsUploading(true);
            try {
                await uploaderRef.current.startUpload();
                fetchBatchPhotos(); // Refresh photos after upload
            } catch (error) {
                console.error('Upload error:', error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const handleSave = (isDone: boolean) => {
        const savedIngredients = ingredients.map(({ id, lot, price }) => ({ id, lot, price }));
        const savedProducts = products.map(({ id, count, toll }) => ({
            id,
            count,
            toll: Number(toll.toFixed(2))
        }));

        onSave(
            {
                ...batch,
                output_notes: formData.outputNotes,
                labor_notes: formData.laborNotes,
                labor_hours: formData.laborHours,
                lot: formData.lotCode,
                count: formData.count,
                exp_date: formData.expDate ? formData.expDate.toISOString() : null,
                ingredients: savedIngredients,
                products: savedProducts,
            },
            isDone
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    margin: 0,
                    width: '100%',
                    maxHeight: '100%',
                    backgroundColor: '#fff',
                },
            }}>
            <BatchHeader batch={batch} formatDate={formatDate} />
            <DialogContent sx={{ p: 3 }}>
                <BatchDetailsForm
                    processData={processData}
                    ingredients={ingredients}
                    setIngredients={setIngredients}
                    products={products}
                    setProducts={setProducts}
                    formData={formData}
                    setFormData={setFormData}
                />

                {/* Photos Section */}
                <Typography variant="h6" sx={{ marginTop: '20px', marginBottom: '10px' }}>
                    Photos
                </Typography>

                {photos.length > 0 && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginBottom: 2 }}>
                        {photos.map((photo) => (
                            <Box
                                key={photo.id}
                                sx={{
                                    position: 'relative',
                                    '&:hover .delete-button': {
                                        opacity: 1
                                    }
                                }}
                            >
                                <img
                                    src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/' + photo.url}
                                    alt={photo.name}
                                    style={{
                                        width: 100,
                                        height: 100,
                                        objectFit: 'cover',
                                        borderRadius: 4,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setPreviewImage(photo)}
                                />
                                <IconButton
                                    className="delete-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeletePhoto(photo.id);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: -8,
                                        right: -8,
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        color: '#fff',
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        '&:hover': {
                                            backgroundColor: '#000',
                                        },
                                        padding: '4px',
                                        '& svg': {
                                            fontSize: '16px'
                                        }
                                    }}
                                    size="small"
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                )}

                {/* Image Preview Dialog */}
                <Dialog
                    open={Boolean(previewImage)}
                    onClose={() => setPreviewImage(null)}
                    maxWidth={false}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            position: 'relative'
                        }
                    }}
                >
                    <DialogContent sx={{ padding: 0, position: 'relative' }}>
                        <IconButton
                            onClick={() => setPreviewImage(null)}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#000',
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {previewImage && (
                            <img
                                src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/' + previewImage.url.replace('-sm-', '-')}
                                alt={previewImage.name}
                                style={{
                                    maxHeight: 'calc(100vh - 64px)',
                                    maxWidth: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                    </DialogContent>
                </Dialog>

                {/* Photo Uploader */}
                <MultiPhotoUploader
                    ref={uploaderRef}
                    endpoint={`${host}/batch-photos`}
                    onUploadComplete={fetchBatchPhotos}
                    additionalMetadata={{
                        batchId: batch.id,
                        clientId
                    }}
                    onError={(error) => console.error('Upload error:', error)}
                />
            </DialogContent>
            <DialogActions sx={{ p: 3, backgroundColor: 'white' }}>
                <Box sx={{ position: 'relative', display: 'flex', gap: 1 }}>
                    {isUploading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: -40,
                                marginTop: '-12px',
                            }}
                        />
                    )}
                    <Button onClick={onClose} color="secondary" variant="contained">Cancel</Button>
                    <Button onClick={handleStartUpload} variant="contained">
                        Upload Photos
                    </Button>
                    <Button onClick={() => handleSave(false)} variant="contained" color="primary">Save</Button>
                    <Button onClick={() => handleSave(true)} variant="contained" color="primary">Done</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default BatchDialog;