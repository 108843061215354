import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow, TableCell, TableBody
} from '@mui/material';
import { ScheduledProcess, IngredientOption, PackagingOption, ProductOption, MetricOption } from '../types';
import ReadOnlyScheduledProcessPanel from '../ScheduledProcess/ReadOnlyScheduledProcessPanel';
import ReactMarkdown from "react-markdown";
import {formatDate} from "../Util";
interface ReadOnlyScheduledProcessProps {
    open: boolean;
    onClose?: () => void;
    onEdit?: () => void;
    shipment: any;
    showButtons: boolean;
    clientName: string;
}

const ReadOnlyShipment: React.FC<ReadOnlyScheduledProcessProps> =
    ({
         open, onClose,
         shipment,
         showButtons,
         clientName
    }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    margin: 0,
                    width: '100%',
                    maxHeight: '100%'
                },
            }}
        >
            <DialogTitle sx={{ marginBottom: '16px', fontSize: '1.5rem' }}>Shipment Details</DialogTitle>
            <DialogContent>
                <Typography variant="h6">Client: {clientName}</Typography>
                <Typography variant="h6">Ship Date: {formatDate(shipment.timestamp)}</Typography>
                <Typography variant="h6">Distributor: {shipment.distributor_name}</Typography>
                <Typography variant="h6">Ticket Number: {shipment.ticket_num}</Typography>
                <Typography variant="h6" style={{ marginTop: '16px' }}>Products</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shipment.products && shipment.products.map((product:any, index:any) => (
                                <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}>
                                    <TableCell>{product.name}<br/>
                                        Lot Code: {product.lot}<br/>
                                        Expiration Date: {formatDate(product.exp_date)}
                                    </TableCell>
                                    <TableCell align="right">{product.count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant="h6" style={{ marginTop: '16px' }}>Notes</Typography>
                <ReactMarkdown>{shipment.notes}</ReactMarkdown>
            </DialogContent>
            {showButtons && (
                <DialogActions sx={{margin: '20px'}}>
                    <Button variant="contained" color="secondary" onClick={onClose}>Close</Button>
                    {/* Don't allow users to edit after Add <Button variant="contained" >Edit</Button>*/}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ReadOnlyShipment;