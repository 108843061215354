import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    AlertTitle,
    Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InspectionList from './InspectionList';
import InspectionCalendar from "./InspectionCalendar";
import { host } from './tableConfig';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "./Util";
import useUserStore from "./store/userStore";

interface Item {
    id: number;
    name: string;
    type: number;
}

interface GroupedItems {
    [key: string]: Item[];
}

const PanelTwo: React.FC = () => {
    const [groupedItems, setGroupedItems] = useState<GroupedItems>({});
    const [expanded, setExpanded] = useState<number | null>(null);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);
    const { user } = useUserStore();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axiosWrapper({ url: `${host}/getSQLResults/lists?clientId=${user?.clientId || 0}` });
                const items: Item[] = response.data.data;
                const grouped = items.reduce((acc: GroupedItems, item) => {
                    let key;
                    if (item.type === 1) key = "Daily";
                    else if (item.type === 7) key = "Weekly";
                    else if (item.type === 30) key = "Monthly";
                    else key = "Other";

                    if (!acc[key]) acc[key] = [];
                    acc[key].push(item);
                    return acc;
                }, {});
                setGroupedItems(grouped);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        fetchItems();
    }, []);

    const handleExpand = (itemId: number) => {
        setExpanded(expanded === itemId ? null : itemId);
    };

    // Check if there are no inspections
    const hasNoInspections = Object.keys(groupedItems).length === 0;

    if (hasNoInspections) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '400px',
                    padding: 2
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        maxWidth: '600px',
                        width: '100%',
                        padding: 3,
                        backgroundColor: 'transparent'
                    }}
                >
                    <Alert
                        severity="info"
                        icon={<InfoOutlinedIcon />}
                        sx={{
                            '& .MuiAlert-message': {
                                width: '100%'
                            }
                        }}
                    >
                        <AlertTitle sx={{ fontWeight: 'bold' }}>
                            No Inspections To Review
                        </AlertTitle>
                        <Typography>
                            This section allows you to review your Inspections. You have not created any Inspections. You can add Inspections from the App Menu: Lists -&gt; Add Inspection Types and Add Inspection Items.
                        </Typography>
                    </Alert>
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
            {Object.entries(groupedItems).map(([groupTitle, items]) => (
                <Box key={groupTitle} sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, marginLeft: '16px', fontSize: '1.2rem' }}>{groupTitle}</Typography>
                    {items.map(item => (
                        <Accordion
                            key={item.id}
                            expanded={expanded === item.id}
                            onChange={() => handleExpand(item.id)}
                            sx={{
                                '&.MuiAccordion-root': {
                                    borderRadius: 0,
                                    //boxShadow: 'none',
                                    border: 'none',
                                    '&:not(:last-child)': {
                                        borderBottom: 0,
                                    },
                                    '&:before': {
                                        display: 'none',
                                    },
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${item.id}-content`}
                                id={`panel-${item.id}-header`}
                            >
                                <Typography variant="body1" sx={{ fontWeight: '500', fontSize: '1rem' }}>{item.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InspectionList listId={item.id} />
                                <InspectionCalendar listId={item.id} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            ))}
        </Box>
    );
};

export default PanelTwo;