import React from 'react';
import {
    Box,
    Typography,
    Collapse,
    IconButton
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import ReadOnlyScheduledProcessPanel from "../../ScheduledProcess/ReadOnlyScheduledProcessPanel";
import HelpDialog, {HelpIconButton} from "./HelpDialog";
import {SCHEDULED_PROCESS_HELP_CONTENT} from "./helpContent";

interface ProcessSectionProps {
    processData: any;
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
}

const ProcessSection: React.FC<ProcessSectionProps> = ({
                                                           processData,
                                                           isExpanded,
                                                           setIsExpanded
                                                       }) => {
    const [helpOpen, setHelpOpen] = React.useState(false);

    return (
        <Box sx={{
            borderBottom: 1,
            borderColor: 'primary.main',
            backgroundColor: 'white',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 3,
                    pb: isExpanded ? 2 : 3
                }}
                onClick={() => setIsExpanded(!isExpanded)}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                        cursor: 'pointer',
                    }}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: 'primary.main',
                            fontWeight: 600
                        }}>
                        Scheduled Process
                    </Typography>
                    <IconButton>
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                <HelpIconButton onClick={(e) => {
                    e.stopPropagation(); // Prevent expand/collapse when clicking help
                    setHelpOpen(true);
                }} />
            </Box>
            <HelpDialog
                open={helpOpen}
                onClose={() => setHelpOpen(false)}
                content={SCHEDULED_PROCESS_HELP_CONTENT}
                title="Ingredient Info Section Help"  // optional
            />
            <Collapse in={isExpanded}>
                <Box sx={{ px: 3, pb: 3 }}>
                    <ReadOnlyScheduledProcessPanel process={processData} />
                </Box>
            </Collapse>
        </Box>
    );
};

export default ProcessSection;