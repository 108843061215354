import React from 'react';
import { Box, Button, Typography, Paper } from '@mui/material';
import { Home, Network, QrCode, User } from 'lucide-react';

interface BottomTabBarProps {
    value: number;
    onChange: (newValue: number) => void;
}

const BottomTabBar: React.FC<BottomTabBarProps> = ({ value, onChange }) => {
    const icons = [
        { icon: <Home size={24} />, label: 'Home' },
        { icon: <Network size={24} />, label: 'Flow' },
        { icon: <QrCode size={24} />, label: 'Track' },
        { icon: <User size={24} />, label: 'Account' }
    ];

    return (
        <Paper
            sx={{
                position: 'sticky', // Changed from 'fixed' to 'sticky'
                bottom: 0,
                left: 0,
                right: 0,
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                backgroundColor: 'white',
                zIndex: 1000 // Ensure it stays above content
            }}
            elevation={0}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                    py: 1,
                    px: 2
                }}
            >
                {icons.map((item, index) => (
                    <Button
                        key={index}
                        onClick={() => onChange(index)}
                        sx={{
                            minWidth: 'auto',
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: value === index ? '#2563eb' : '#666',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: value === index ? '#2563eb' : '#444'
                            },
                            py: 1,
                            px: 2
                        }}
                    >
                        {item.icon}
                        <Typography
                            variant="caption"
                            sx={{
                                mt: 0.5,
                                fontSize: '0.75rem',
                                textTransform: 'none'
                            }}
                        >
                            {item.label}
                        </Typography>
                    </Button>
                ))}
            </Box>
        </Paper>
    );
};

export default BottomTabBar;