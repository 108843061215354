import React, { useEffect, useState, useMemo } from 'react';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    TableContainer,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography, Paper
} from '@mui/material';
import { createAxiosWrapper } from "../Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import AddShipmentDialog from "./AddShipmentDialog";
import { host } from "../tableConfig";
import { formatDate } from "../Util";
import EditShipmentDialog from "./EditShipmentDialog";
import useUserStore from '../store/userStore';

type Order = 'asc' | 'desc';

const Shipping: React.FC = () => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [shipments, setShipments] = useState<any[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<any>('timestamp');
    const [selectedClientName, setSelectedClientName] = useState<string>('');

    const { getToken } = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    // Get user and clients from the store
    const { user } = useUserStore();

    // Memoize the clients array
    const clients = useMemo(() => user?.clients || [], [user?.clients]);

    // Determine if we're dealing with a single client
    const isSingleClient = useMemo(() => clients.length <= 1, [clients]);

    useEffect(() => {
        if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId);
            setSelectedClientName(clients[0]?.name || user?.organization || '');
        }
    }, [isSingleClient, user?.clientId, clients, user?.organization]);

    useEffect(() => {
        if (selectedClientId) {
            fetchShipments();
        }
    }, [selectedClientId]);

    const handleAdd = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const fetchShipments = async () => {
        if (!selectedClientId) return;
        try {
            const response = await axiosWrapper({ url: `${host}/shipping?clientId=${selectedClientId}` });
            const data = response.data;
            if (data.result === 'success') {
                setShipments(data.data);
            }
        } catch (error) {
            console.error('Error fetching shipments:', error);
        }
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleSave = async (newShipment: any) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/shipping`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: { ...newShipment, client: selectedClientId },
            });
            const data = response.data;
            if (data.result === 'success') {
                await fetchShipments();
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error saving shipment:', error);
            return false;
        }
    };

    const handleRequestSort = (property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedShipments = React.useMemo(() => {
        const comparator = (a: any, b: any) => {
            if (!a.hasOwnProperty(orderBy) || !b.hasOwnProperty(orderBy)) {
                return 0;
            }

            const aValue = a[orderBy];
            const bValue = b[orderBy];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return order === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }

            if (aValue !== undefined && bValue !== undefined) {
                if (aValue < bValue) {
                    return order === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return order === 'asc' ? 1 : -1;
                }
            }
            return 0;
        };

        return [...shipments].sort(comparator);
    }, [shipments, order, orderBy]);

    const handleEditProcess = (id: number) => {
        setSelectedId(id);
        setOpenEditDialog(true);
    };

    const handleUpdate = async (updatedProcess: any) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/shipping/${updatedProcess.id}`,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: { ...updatedProcess, clientId: selectedClientId },
            });
            const data = response.data;
            if (data.result === 'success') {
                await fetchShipments();
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating process:', error);
            return false;
        }
    };

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        const selectedClient = clients.find(client => client.id === clientId);
        setSelectedClientName(selectedClient ? selectedClient.name : '');
    };

    // Memoize the client menu items
    const clientMenuItems = useMemo(() =>
            clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                    {client.name}
                </MenuItem>
            )),
        [clients]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '12px', paddingRight: '12px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '20px', marginBottom: '20px', }}>
                {!isSingleClient ? (
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="client-select-label">Client</InputLabel>
                        <Select
                            sx={{ width: '250px' }}
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClientId}
                            label="Client"
                            onChange={handleClientChange}
                        >
                            {clientMenuItems}
                        </Select>
                    </FormControl>
                ) : (
                    selectedClientName && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Client: {selectedClientName}
                        </Typography>
                    )
                )}
                {selectedClientId && (
                    <Button sx={{marginTop: '15px'}} variant="contained" color="primary" onClick={handleAdd}>
                        Add Shipment
                    </Button>
                )}
            </Box>
            {selectedClientId && (
                <TableContainer component={Paper} style={{ marginTop: '20px', maxWidth: '800px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'timestamp'}
                                        direction={orderBy === 'timestamp' ? order : 'desc'}
                                        onClick={() => handleRequestSort('timestamp')}>
                                        <span>Shipment Date</span>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'distributor_name'}
                                        direction={orderBy === 'distributor_name' ? order : 'desc'}
                                        onClick={() => handleRequestSort('distributor_name')}>
                                        <span>Distributor</span>
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedShipments.map((shipment) => (
                                <TableRow sx={{cursor:'pointer'}} key={shipment.id} onClick={() => handleEditProcess(shipment.id!)}>
                                    <TableCell>{formatDate(shipment.timestamp)}</TableCell>
                                    <TableCell>{shipment.distributor_name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <AddShipmentDialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                onSave={handleSave}
                clientName={selectedClientName}
            />
            {selectedId !== null && (
                <EditShipmentDialog
                    open={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onSave={handleUpdate}
                    shipmentId={selectedId}
                    clientName={selectedClientName}
                />
            )}
        </Box>
    );
};

export default Shipping;