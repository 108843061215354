import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import getMenuItems, { MenuItem } from './menuItems';
import {uniq, uniqBy} from "lodash";

interface SubMenuProps {
    itemTitles: string[];
    onAction: (action: string) => void;
}

interface FoundMenuItem extends MenuItem {
    path?: string;
}

const SubMenu: React.FC<SubMenuProps> = ({ itemTitles, onAction }) => {
    const findMenuItems = (titles: string[]): FoundMenuItem[] => {
        const allMenuItems = getMenuItems(() => {}, () => {});
        const foundItems: FoundMenuItem[] = [];

        titles.forEach(searchTitle => {
            // Search through all menu items and their subitems
            for (const mainItem of allMenuItems) {
                if (mainItem.subItems) {
                    for (const subItem of mainItem.subItems) {
                        if (subItem.title === searchTitle) {
                            foundItems.push({
                                ...subItem,
                                path: `${mainItem.title}/${subItem.title}`
                            });
                            break;
                        }
                    }
                }
                // Also check main items
                if (mainItem.title === searchTitle) {
                    foundItems.push({
                        ...mainItem,
                        path: mainItem.title
                    });
                }
            }
        });

        return foundItems; //uniqBy(foundItems, 'title');
    };

    const menuItems = findMenuItems(itemTitles);

    return (
        <Box sx={{
            maxWidth: {
                xs: '100%',
                md: '1600px'
            },
            mx: 'auto',
            px: {
                xs: 2,
                md: 4
            },
            py: 3,
        }}>
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                {menuItems.map((item, index) => (
                    <Grid item xs={6} md={3} key={index}>

                        <Button
                            variant="contained"
                            onClick={() => onAction(item.path || item.title)}
                            sx={{
                                display: 'flex !important',
                                flexDirection: 'column !important',
                                alignItems: 'center !important',
                                justifyContent: 'center !important',
                                minHeight: {
                                    xs: '90px',
                                    md: '100px'
                                },
                                width: '100%',
                                backgroundColor: 'white',
                                borderRadius: 2,
                                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                                transition: 'all 0.2s',
                                padding: 0,
                                '&:hover': {
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                    transform: 'translateY(-2px)',
                                    backgroundColor: 'white'
                                }
                            }}
                        >
                            <Box sx={{
                                color: 'text.primary',
                                mb: 0.5,
                                '& > svg': {
                                    width: 30,
                                    height: 30,
                                    strokeWidth: 2
                                }
                            }}>
                                {item.icon}
                            </Box>
                            <Typography
                                sx={{
                                    color: 'text.secondary',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    lineHeight: 1.2,
                                    fontSize: '14px'
                                }}
                            >
                                {item.title}
                            </Typography>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SubMenu;