import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';

const FeatureNotInstalled = () => {
    return (
        <Box sx={{
            maxWidth: 400,
            margin: '60px auto',
            width: '100%'
        }}>
            <Alert severity="error">
                <AlertTitle>Feature Not Available</AlertTitle>
                This feature is not installed. Please contact your system administrator for installation.
            </Alert>
        </Box>
    );
};

export default FeatureNotInstalled;