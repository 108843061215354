import React, { useState } from 'react';
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography
} from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { MenuItem } from './menuItems';

interface MenuItemWithSubItems extends MenuItem {
    subItems: MenuItem[];
}

function hasSubItems(item: MenuItem): item is MenuItemWithSubItems {
    return 'subItems' in item && Array.isArray((item as any).subItems);
}

interface SideMenuProps {
    isOpen: boolean;
    onClose: () => void;
    logout: () => void;
    handleNodeClick: (nodeName: string) => void;
    handleMenuAction: (action: string) => void;
    menuItems: MenuItem[];
}

const SideMenu: React.FC<SideMenuProps> = ({
                                               isOpen,
                                               onClose,
                                               logout,
                                               handleNodeClick,
                                               handleMenuAction,
                                               menuItems
                                           }) => {
    const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);

    const toggleSubmenu = (index: number): void => {
        setOpenSubmenu(openSubmenu === index ? null : index);
    };

    const customIconTitles: string[] = ['Ingredient Counts', 'Product Counts'];
    const isCustomIcon = (title: string | null): boolean => {
        if (!title) return false;
        return customIconTitles.includes(title);
    };

    return (
        <Drawer
            anchor="left"
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: 280,
                    backgroundColor: 'white',
                    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                }
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                role="presentation"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
                onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Escape') onClose();
                }}
            >
                <Box sx={{
                    p: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                }}>
                    <Typography variant="h6" sx={{
                        fontWeight: 600,
                        color: 'text.primary'
                    }}>
                        Menu
                    </Typography>
                </Box>

                <List sx={{
                    flex: 1,
                    pt: 1,
                    pb: 2
                }}>
                    {menuItems.map((item: MenuItem, index: number) => {
                        const itemHasSubItems: boolean = hasSubItems(item);
                        return (
                            <React.Fragment key={item.title}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        if (item.title === "Logout") {
                                            logout();
                                        } else if (itemHasSubItems) {
                                            toggleSubmenu(index);
                                        } else {
                                            handleMenuAction(item.title);
                                        }
                                    }}
                                    sx={{
                                        py: 1.5,
                                        px: 2,
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                        transition: 'background-color 0.2s',
                                    }}
                                >
                                    {!isCustomIcon(item.title) && (
                                        <ListItemIcon sx={{
                                            minWidth: 40,
                                            color: 'text.primary'
                                        }}>
                                            {item.icon}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                fontWeight: 500,
                                                color: 'text.primary'
                                            }}>
                                                {item.title}
                                            </Typography>
                                        }
                                    />
                                    {itemHasSubItems && (
                                        <Box sx={{ color: 'text.secondary' }}>
                                            {openSubmenu === index ?
                                                <ChevronUp size={18} /> :
                                                <ChevronDown size={18} />
                                            }
                                        </Box>
                                    )}
                                </ListItem>
                                {itemHasSubItems && (
                                    <Collapse in={openSubmenu === index} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {(item as MenuItemWithSubItems).subItems.map((subItem: MenuItem) => (
                                                <ListItem
                                                    button
                                                    key={subItem.title}
                                                    onClick={() => handleMenuAction(`${item.title}/${subItem.title}`)}
                                                    sx={{
                                                        py: 1.25,
                                                        pl: 6,
                                                        pr: 2,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                                        },
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography  sx={{
                                                                color: 'text.secondary',
                                                                fontWeight: 400
                                                            }}>
                                                                {subItem.title}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </Drawer>
    );
};

export default SideMenu;