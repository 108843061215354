import React from 'react';

interface StatusSquareProps {
    status: string;
}

export const getStatusColor = (status: string) => {
    switch (status) {
        case 'R': return '#ef4444';
        case 'Y': return '#eab308';
        case 'G': return '#22c55e';
        default: return '#gray';
    }
};

const StatusSquare: React.FC<StatusSquareProps> = ({ status }) => {
    return (
        <span style={{
            color: getStatusColor(status),
            fontSize: '24px',
            lineHeight: 1,
            display: 'flex',
            alignItems: 'center',
            minWidth: '24px'
        }}>
      ■
    </span>
    );
};

export default StatusSquare;