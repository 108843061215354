import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField,
    Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Chip, Box,
    Switch, FormControlLabel
} from '@mui/material';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper, formatDate } from "./Util";
import { host } from './tableConfig';
import {Invoice, Batch, BatchData} from './types';

interface AddInvoiceDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (invoice: Omit<Invoice, 'id' | 'timestamp'>) => Promise<boolean>;
    clientId: number;
    clientName: string;
}

const AddInvoiceDialog: React.FC<AddInvoiceDialogProps> = ({ open, onClose, onSave, clientId, clientName }) => {
    const [notes, setNotes] = useState('');
    const [selectedBatches, setSelectedBatches] = useState<Batch[]>([]);
    const [availableBatches, setAvailableBatches] = useState<BatchData[]>([]);
    const [paid, setPaid] = useState(false);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        if (open) {
            fetchBatches();
            resetForm();
        }
    }, [open, clientId]);

    const fetchBatches = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/getSQLResults/list_free_batches/${clientId}` });
            if (response.data && response.data.result === 'success') {
                setAvailableBatches(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching batches:', error);
        }
    };

    const resetForm = () => {
        setNotes('');
        setSelectedBatches([]);
        setPaid(false);
    };

    const handleBatchChange = (event: SelectChangeEvent<number>) => {
        const batchId = event.target.value as number;
        const selectedBatch = availableBatches.find(batch => batch.id === batchId);
        if (selectedBatch) {
            const newBatch: Batch = {
                id: selectedBatch.id,
                process_name: selectedBatch.process_name,
                timestamp: selectedBatch.timestamp,
                products: []
            };
            setSelectedBatches([...selectedBatches, newBatch]);
            setAvailableBatches(availableBatches.filter(batch => batch.id !== batchId));
        }
    };

    const handleRemoveBatch = (batchId: number) => {
        const removedBatch = selectedBatches.find(batch => batch.id === batchId);
        if (removedBatch) {
            setSelectedBatches(selectedBatches.filter(batch => batch.id !== batchId));
            setAvailableBatches([...availableBatches, {
                id: removedBatch.id,
                process_name: removedBatch.process_name,
                timestamp: removedBatch.timestamp,
                process: 0, // You might need to adjust this value
                client_name: clientName,
                close_timestamp: null,
                labor_notes: null,
                output_notes: null,
                labor_hours: null,
                lot: null
            }]);
        }
    };

    const handleSave = async () => {
        const newInvoice: Omit<Invoice, 'id' | 'timestamp'> = {
            clientId,
            clientName,
            batches: selectedBatches,
            notes,
            paid
        };
        const success = await onSave(newInvoice);
        if (success) {
            handleClose();
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Add Invoice for {clientName}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Add Batch</InputLabel>
                    <Select onChange={handleBatchChange} value="">
                        {availableBatches.map((batch) => (
                            <MenuItem key={batch.id} value={batch.id}>
                                {`[${batch.id}] ${batch.process_name} - ${formatDate(batch.timestamp)}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 2, mb: 2 }}>
                    {selectedBatches.map((batch) => (
                        <Chip
                            key={batch.id}
                            label={`${batch.process_name} - ${formatDate(batch.timestamp)}`}
                            onDelete={() => handleRemoveBatch(batch.id)}
                        />
                    ))}
                </Box>
                <TextField
                    margin="normal"
                    label="Notes"
                    fullWidth
                    multiline
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
                <FormControlLabel
                    control={<Switch checked={paid} onChange={(e) => setPaid(e.target.checked)} />}
                    label="Paid"
                />
            </DialogContent>
            <Box sx={{marginRight: '20px', marginLeft: '20px'}}>
            <DialogActions>
                <Button variant="contained"  color="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="contained"  onClick={handleSave}>Save</Button>
            </DialogActions>
            </Box>
        </Dialog>
    );
};

export default AddInvoiceDialog;