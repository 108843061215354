import React, { useEffect, useState } from "react";
import { Box, Typography, Select, MenuItem, Button, SelectChangeEvent } from '@mui/material';
import { host } from "../tableConfig";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import AlbumDialog from "./AlbumDialog";
import AddPhotosDialog from "./AddPhotosDialog";

interface PhotoMenuProps {
    onAction: (action: string) => void;
}

const PhotoMenu: React.FC<PhotoMenuProps> = ({ onAction }) => {
    const [albums, setAlbums] = useState<any[]>([]);
    const [selectedAlbum, setSelectedAlbum] = useState('');
    const [openAlbumDialog, setOpenAlbumDialog] = useState(false);
    const [openAddPhotosDialog, setOpenAddPhotosDialog] = useState(false);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        fetchAlbums();
    }, []);

    const fetchAlbums = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/getSQLResults/list_albums` });
            const data = response.data;
            if (data.result === 'success') {
                setAlbums(data.data);
            }
        } catch (error) {
            console.error('Error fetching albums:', error);
        }
    };

    const handleAlbumChange = (event: SelectChangeEvent<string>) => {
        setSelectedAlbum(event.target.value as string);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            maxWidth: '300px',
            margin: '20px auto',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: '20px',
                marginBottom: '20px',
                gap: 2
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddPhotosDialog(true)}
                >
                    Add Photos
                </Button>
            </Box>

            <Typography sx={{ textAlign: 'center', marginBottom: '16px' }} variant="h4">
                Albums
            </Typography>
            <Select
                value={selectedAlbum}
                onChange={handleAlbumChange}
                displayEmpty
                fullWidth
                sx={{ marginBottom: '16px' }}
            >
                <MenuItem value="" disabled>Select an album</MenuItem>
                {albums.map((album) => (
                    <MenuItem key={album.id} value={album.id}>{album.name}</MenuItem>
                ))}
            </Select>
            <Button
                variant="contained"
                onClick={() => setOpenAlbumDialog(true)}
                disabled={!selectedAlbum}>
                View
            </Button>

            <AlbumDialog
                open={openAlbumDialog}
                onClose={() => setOpenAlbumDialog(false)}
                albumId={selectedAlbum}
                albumName={albums.find(album => album.id === selectedAlbum)?.name || ''}
            />

            <AddPhotosDialog
                open={openAddPhotosDialog}
                onClose={() => setOpenAddPhotosDialog(false)}
                onComplete={() => {
                    fetchAlbums();
                    setOpenAddPhotosDialog(false);
                }}
                albums={albums}
            />
        </Box>
    );
};

export default PhotoMenu;