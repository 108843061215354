
import BaseFileUploader, { FileUploaderRef } from './BaseFileUploader';
import { forwardRef } from 'react';

interface DocumentUploaderProps {
    endpoint: string;
    onUploadComplete: () => void;
    additionalMetadata?: Record<string, any>;
    onError?: (error: string) => void;
}

const DocumentUploader = forwardRef<FileUploaderRef, DocumentUploaderProps>((props, ref) => {
    return (
        <BaseFileUploader
            {...props}
            ref={ref}
            allowedFileTypes={[
                '.pdf', '.doc', '.docx', '.txt', '.xls', '.xlsx',
                '.jpg', '.jpeg', '.png' // Include image types for scanned documents
            ]}
            fieldName="document"
            thumbnailGenerator={false}
        />
    );
});

export default DocumentUploader;