import React, {useState, useEffect, useRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Typography,
    Box,
    CircularProgress,
    Select,
    MenuItem,
    InputLabel,
    SelectChangeEvent,
    useTheme,
    useMediaQuery,
    IconButton, Alert
} from '@mui/material';
import {Camera, Close as DeleteIcon, Close as CloseIcon} from '@mui/icons-material';
import {Column} from '../TablePanel';
import {createAxiosWrapper, formatCurrency} from "../Util";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {host} from '../tableConfig';
import PhotoUploader from "../Photos/PhotoUploader";
import {FileUploaderRef} from "../BaseFileUploader";
import NotesSection from "./NotesSection";

interface Item {
    id: number;

    [key: string]: any;
}

interface Photo {
    id: number;
    url: string;
    name: string;
}

interface SelectOption {
    id: number | string;

    [key: string]: any;
}

interface ItemDialogProps {
    open: boolean;
    onClose: () => void;
    onSave?: (item: Omit<Item, 'id'>) => Promise<void>;
    itemId?: number;
    mode: 'add' | 'view' | 'edit';
    onModeChange?: (newMode: 'add' | 'view' | 'edit') => void;
    columns: readonly Column[];
    itemTitle: string;
    clientId: number | string;
    clientName: string;
    docType?: string;
    ro?: boolean; // read-only
}

function nullToEmptyStringInPlace(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null) {
            obj[key] = '';
        }
    });
    return obj;
}

const ItemDialogPlusPhotos: React.FC<ItemDialogProps> = ({
        open,
        onClose,
        onSave,
        itemId,
        mode,
        onModeChange,
        columns,
        itemTitle,
        clientId,
        clientName,
        docType = 'Photo',
        ro
    }) => {
    const [formData, setFormData] = useState<Omit<Item, 'id'>>({});
    const [isSaving, setIsSaving] = useState(false);
    const [selectOptions, setSelectOptions] = useState<{ [key: string]: SelectOption[] }>({});
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [item, setItem] = useState<any>(null)
    const [previewImage, setPreviewImage] = useState<{ url: string; name: string } | null>(null);
    const uploaderRef = useRef<FileUploaderRef>(null);
    const [itemNotes, setItemNotes] = useState<Array<{ notes: string; status: string }>>([]);

    const {getToken} = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isViewMode = mode === 'view';

    useEffect(() => {
        if (item) {
            const cleanedItem = nullToEmptyStringInPlace({...item});
            setFormData(cleanedItem);
        } else {
            const newFormData: { [key: string]: any } = {};
            columns.forEach(column => {
                newFormData[column.id] = column.type === 'radio' ? (column.options?.[0] || '') : '';
            });
            setFormData(newFormData);
            setItemNotes([]); // Add this line
        }

        columns.forEach(column => {
            if (column.type === 'select' && column.endpoint) {
                fetchSelectOptions(column.id, column.byClient, column.endpoint);
            }
        });
    }, [item, open, columns]);

    useEffect(() => {
        if (itemId) {
            fetchItem()
            fetchItemPhotos()
        }
    }, [itemId]);

    const fetchItem = async () => {
        if (!itemId) return;
        try {
            const response = await axiosWrapper({
                url: `${host}/ticket/${itemId}`
            });
            const data = response.data;
            if (data.result === 'success') {
                setItem(data.data);
                // Extract and set the notes
                setItemNotes((data.data.itemNotes || []).map(note => ({
                    notes: note.notes,
                    status: note.status,
                    timestamp: note.timestamp,
                    user_name: note.user_name,
                    id: note.notes
                })));
            }
        } catch (error) {
            console.error(`Error fetching item:`, error);
        }
    };


    const fetchItemPhotos = async () => {
        if (!itemId) return;
        try {
            const response = await axiosWrapper({
                url: `${host}/item-photos?fk=${itemId}&ft=${itemTitle}`
            });
            const data = response.data;
            if (data.result === 'success') {
                setPhotos(data.data || []);
            }
        } catch (error) {
            console.error(`Error fetching item ${docType}:`, error);
        }
    };

    const handleDeletePhoto = async (photoId: number, e: React.MouseEvent) => {
        e.stopPropagation();
        try {
            const response = await axiosWrapper({
                url: `${host}/item-photos/${photoId}`,
                method: 'DELETE'
            });

            if (response.data.result === 'success') {
                fetchItemPhotos();
            } else {
                console.error('Failed to delete photo:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    };

    const handleStartUpload = async () => {
        if (uploaderRef.current && item?.id) {
            setIsUploading(true);
            try {
                await uploaderRef.current.startUpload();
                fetchItemPhotos();
            } catch (error) {
                console.error('Upload error:', error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const fetchSelectOptions = async (columnId: string, byClient: boolean | undefined, endpoint: string) => {
        try {
            let url = endpoint;
            if (byClient) {
                url = `${endpoint}?clientId=${clientId || 0}`;
            }
            const response = await axiosWrapper({url});
            if (response.data.result === 'success' && Array.isArray(response.data.data)) {
                setSelectOptions(prev => ({
                    ...prev,
                    [columnId]: response.data.data
                }));
            }
        } catch (error) {
            console.error(`Error fetching options for ${columnId}:`, error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name as string]: value}));
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleSave = async () => {
        if (!onSave) return;
        setIsSaving(true);
        try {
            await onSave({
                ...formData,
                itemNotes
            });
            onModeChange?.('view');
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
        } finally {
            setIsSaving(false);
        }
    };

    const renderClientName = () => (
        <Box mb={2}>
            <Typography variant="subtitle2">Client</Typography>
            <Typography variant="body1">{clientName}</Typography>
        </Box>
    );

    const renderField = (column: Column, value: string | number) => {
        let displayValue: string | number = value;

        if (column.id === 'price' && value) {
            displayValue = formatCurrency(value);
        }

        if (value === '' || value === undefined || value === null) {
            displayValue = 'Not set';
        } else if (column.type === 'radio' && column.options) {
            displayValue = column.options[Number(value)] || value;
        } else if (column.type === 'select' && selectOptions[column.id]) {
            const option = selectOptions[column.id].find(opt => opt.id.toString() === value.toString());
            displayValue = option ? option.brand_name || option.name || value : value;
        }

        return (
            <Box key={column.id} mb={2}>
                <Typography variant="subtitle2">{column.label}</Typography>
                <Typography variant="body1">{displayValue}</Typography>
            </Box>
        );
    };

    const renderInput = (column: Column) => {
        if (column.type === 'radio' && column.options) {
            return (
                <FormControl component="fieldset" margin="normal" key={column.id}>
                    <FormLabel component="legend">{column.label}</FormLabel>
                    <RadioGroup
                        aria-label={column.id}
                        name={column.id}
                        value={formData[column.id]?.toString() || ''}
                        onChange={handleRadioChange}
                    >
                        {column.options.map((option: any, index: any) => (
                            <FormControlLabel key={option} value={index.toString()} control={<Radio/>} label={option}/>
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        } else if (column.type === 'select') {
            const currentValue = formData[column.id];
            const options = selectOptions[column.id] || [];
            const hasMatchingOption = options.some(opt => opt.id.toString() === currentValue?.toString());

            return (
                <FormControl fullWidth margin="normal" key={column.id}>
                    <InputLabel id={`${column.id}-label`}>{column.label}</InputLabel>
                    <Select
                        labelId={`${column.id}-label`}
                        id={column.id}
                        name={column.id}
                        value={hasMatchingOption ? currentValue : ''}
                        onChange={handleSelectChange}
                        label={column.label}
                    >
                        {!hasMatchingOption && currentValue && (
                            <MenuItem value={currentValue}>
                                {currentValue} (Current Value)
                            </MenuItem>
                        )}
                        {options.map((option: SelectOption) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.brand_name || option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else {
            return (
                <TextField
                    key={column.id}
                    name={column.id}
                    label={column.label}
                    value={formData[column.id] || ''}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    multiline={column.rows !== undefined}
                    rows={column.rows}
                />
            );
        }
    };

    const renderPhotoNote = () => (
        <Alert
            icon={<Camera className="h-5 w-5"/>}
            severity="info"
            sx={{
                mt: 2,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '& .MuiAlert-icon': {
                    mr: 1
                }
            }}
        >
            Photos can be added after save.
        </Alert>
    );

    const renderPhotosSection = () => (
        <>
            <Typography variant="h6" sx={{marginTop: '20px', marginBottom: '10px'}}>
                {docType}s
            </Typography>

            {photos.length > 0 && (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2, marginBottom: 2}}>
                    {photos.map((photo) => (
                        <Box
                            key={photo.id}
                            sx={{
                                position: 'relative',
                                '&:hover .delete-button': {
                                    opacity: 1
                                }
                            }}
                        >
                            <img
                                src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/' + photo.url}
                                alt={photo.name}
                                style={{
                                    width: 100,
                                    height: 100,
                                    objectFit: 'cover',
                                    borderRadius: 4,
                                    cursor: 'pointer'
                                }}
                                onClick={() => setPreviewImage(photo)}
                            />
                            {mode === 'edit' && (
                                <IconButton
                                    className="delete-button"
                                    onClick={(e) => handleDeletePhoto(photo.id, e)}
                                    sx={{
                                        position: 'absolute',
                                        top: -8,
                                        right: -8,
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        color: '#fff',
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        '&:hover': {
                                            backgroundColor: '#000',
                                        },
                                        padding: '4px',
                                        '& svg': {
                                            fontSize: '16px'
                                        }
                                    }}
                                    size="small"
                                >
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>
                            )}
                        </Box>
                    ))}
                </Box>
            )}

            {/* Photo Uploader */}
            {mode === 'edit' && itemId && (
                <PhotoUploader
                    ref={uploaderRef}
                    endpoint={`${host}/item-photos?ft=${itemTitle}&fk=${itemId}`}
                    onUploadComplete={fetchItemPhotos}
                    additionalMetadata={{
                        itemId: itemId,
                        clientId
                    }}
                    onError={(error: any) => console.error('Upload error:', error)}
                />
            )}
        </>
    );

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="md"
                fullWidth
                fullScreen={fullScreen}
                PaperProps={{
                    sx: {
                        m: fullScreen ? 0 : 2,
                        height: fullScreen ? '100%' : 'auto',
                        maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)'
                    }
                }}
            >
                <DialogTitle>
                    {isViewMode ? `${itemTitle} Details` : (mode === 'edit' ? `Edit ${itemTitle}` : `Add ${itemTitle}`)}
                </DialogTitle>
                <DialogContent>
                    {isViewMode ? (
                        <>
                            {renderClientName()}
                            {columns.map(column => renderField(column, formData[column.id]))}
                            <NotesSection
                                notes={itemNotes}
                                onNotesChange={setItemNotes}
                                isViewMode={isViewMode}
                            />
                            {renderPhotosSection()}
                        </>
                    ) : (
                        <>
                            {renderClientName()}
                            {columns.map(column => renderInput(column))}
                            {mode === 'edit' && (
                                <>
                                    <NotesSection
                                        notes={itemNotes}
                                        onNotesChange={setItemNotes}
                                        isViewMode={isViewMode}
                                    />
                                    {renderPhotosSection()}
                                </>
                            )}
                        </>
                    )}
                </DialogContent>
                {mode === 'add' && renderPhotoNote()}
                <DialogActions>
                    <Box sx={{position: 'relative', display: 'flex', gap: 2, marginBottom: '20px', marginRight: '20px'}}>
                        {isUploading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: -40,
                                    marginTop: '-12px',
                                }}
                            />
                        )}
                        <Button variant="contained" color="secondary" onClick={onClose}>
                            {isViewMode ? 'Close' : 'Cancel'}
                        </Button>
                        {mode === 'edit' && (
                            <Button onClick={handleStartUpload} variant="contained">
                                Upload {docType}
                            </Button>
                        )}
                        {isViewMode && !ro && onSave && <Button variant="contained" onClick={() => onModeChange?.('edit')}>Edit</Button>}
                        {!isViewMode && onSave && (
                            <Button variant="contained" onClick={handleSave} disabled={isSaving}>
                                {isSaving ? <CircularProgress size={24}/> : 'Save'}
                            </Button>
                        )}
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Image Preview Dialog */}
            <Dialog
                open={Boolean(previewImage)}
                onClose={() => setPreviewImage(null)}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        position: 'relative'
                    }
                }}
            >
                <DialogContent sx={{padding: 0, position: 'relative'}}>
                    <IconButton
                        onClick={() => setPreviewImage(null)}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#000',
                            },
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    {previewImage && (
                        <img
                            src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/' + previewImage.url.replace('-sm-', '-')}
                            alt={previewImage.name}
                            style={{
                                maxHeight: 'calc(100vh - 64px)',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ItemDialogPlusPhotos;