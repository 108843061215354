import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    TextField,
    Alert,
    AlertTitle,
    Paper,
    CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThreeWaySwitch from './ThreeWaySwitch';
import { host } from './tableConfig';
import { createAxiosWrapper } from './Util';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import useUserStore from "./store/userStore";

interface Item {
    id: number;
    name: string;
    type: number;
}

interface ListItem {
    id: number;
    name: string;
    status: string | null;
}

interface GroupedItems {
    [key: string]: Item[];
}

const statusMap: { [key: string]: number } = {
    red: 0,
    yellow: 1,
    green: 2,
    null: -1,
};

const PanelOne: React.FC = () => {
    const [groupedItems, setGroupedItems] = useState<GroupedItems>({});
    const [expanded, setExpanded] = useState<number | null>(null);
    const [listItems, setListItems] = useState<{ [key: number]: ListItem[] }>({});
    const [notes, setNotes] = useState<{ [key: number]: string }>({});
    const [isLoading, setIsLoading] = useState(true);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const { user } = useUserStore();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setIsLoading(true);
                const clientId = user?.clientId || 0;
                const response = await axiosWrapper({ url: `${host}/getSQLResults/lists?clientId=${clientId}` });
                const items: Item[] = response.data.data;
                const grouped = items.reduce((acc: GroupedItems, item) => {
                    let key;
                    if (item.type === 1) key = "Daily";
                    else if (item.type === 7) key = "Weekly";
                    else if (item.type === 30) key = "Monthly";
                    else key = "Other";

                    if (!acc[key]) acc[key] = [];
                    acc[key].push(item);
                    return acc;
                }, {});
                setGroupedItems(grouped);
            } catch (error) {
                console.error('Error fetching items:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchItems();
    }, []);

    const handleExpand = async (itemId: number) => {
        if (expanded === itemId) {
            setExpanded(null);
            return;
        }

        if (!listItems[itemId]) {
            try {
                const response = await axiosWrapper({ url: `${host}/getSQLResults/list_items/${itemId}` });
                const dataWithDefaultStatus = response.data.data.map((item: ListItem) => ({
                    ...item,
                    status: null,
                }));
                setListItems(prev => ({ ...prev, [itemId]: dataWithDefaultStatus }));
            } catch (error) {
                console.error('Error fetching list items:', error);
            }
        }

        setExpanded(itemId);
    };

    const handleSwitchChange = (itemId: number, listItemId: number, newValue: string) => {
        setListItems(prev => ({
            ...prev,
            [itemId]: prev[itemId].map(li =>
                li.id === listItemId ? { ...li, status: newValue } : li
            )
        }));
    };

    const handleNotesChange = (itemId: number, value: string) => {
        setNotes(prev => ({
            ...prev,
            [itemId]: value
        }));
    };

    const handleSave = async (itemId: number) => {
        const updatedListItems = listItems[itemId].map(listItem => ({
            [listItem.id]: statusMap[listItem.status || 'null']
        }));
        const payload = [{
            list_id: itemId,
            values: updatedListItems,
            notes: notes[itemId] || ''
        }];

        try {
            const response = await axiosWrapper({ url: `${host}/insert-inspection`, data: payload, method: 'post' });
            console.log('Save successful:', response.data);
            setExpanded(null);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '400px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    // Only check for no inspections after loading is complete
    const hasNoInspections = Object.keys(groupedItems).length === 0;

    if (hasNoInspections) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '400px',
                    padding: 2
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        maxWidth: '600px',
                        width: '100%',
                        padding: 3,
                        backgroundColor: 'transparent'
                    }}
                >
                    <Alert
                        severity="info"
                        icon={<InfoOutlinedIcon />}
                        sx={{
                            '& .MuiAlert-message': {
                                width: '100%'
                            }
                        }}
                    >
                        <AlertTitle sx={{ fontWeight: 'bold' }}>
                            No Inspections Configured
                        </AlertTitle>
                        <Typography>
                            You can add Inspections from the App Menu: Lists -&gt; Add Inspection Types and Add Inspection Items.
                        </Typography>
                    </Alert>
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
            {Object.entries(groupedItems).map(([groupTitle, items]) => (
                <Box key={groupTitle} sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, marginLeft: '16px' }}>{groupTitle}</Typography>
                    {items.map(item => (
                        <Accordion
                            key={item.id}
                            expanded={expanded === item.id}
                            onChange={() => handleExpand(item.id)}
                            sx={{
                                marginLeft: '8px',
                                marginRight: '8px',
                                '&.MuiAccordion-root': {
                                    borderRadius: 0,
                                    border: 'none',
                                    '&:not(:last-child)': {
                                        borderBottom: 0,
                                    },
                                    '&:before': {
                                        display: 'none',
                                    },
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${item.id}-content`}
                                id={`panel-${item.id}-header`}
                                sx={{
                                    '&.MuiAccordionSummary-root': {
                                        borderRadius: 0,
                                    },
                                }}
                            >
                                <Typography variant="body1" sx={{ fontWeight: '500' }}>{item.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {listItems[item.id]?.map(listItem => (
                                    <Box
                                        key={listItem.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '8px 0px',
                                        }}
                                    >
                                        <Typography sx={{ textAlign: 'left' }} >{listItem.name}</Typography>
                                        <ThreeWaySwitch
                                            value={listItem.status}
                                            onChange={(newValue) => handleSwitchChange(item.id, listItem.id, newValue)}
                                        />
                                    </Box>
                                ))}
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    label="Notes"
                                    value={notes[item.id] || ''}
                                    onChange={(e) => handleNotesChange(item.id, e.target.value)}
                                    sx={{ marginTop: '20px' }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => handleSave(item.id)}
                                    sx={{
                                        marginTop: '20px',
                                        fontSize: '18px',
                                        padding: '10px 20px',
                                        '&:hover': {
                                            backgroundColor: 'darkgreen',
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            ))}
        </Box>
    );
};

export default PanelOne;