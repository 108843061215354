import BaseFileUploader, { FileUploaderRef } from '../BaseFileUploader';
import { forwardRef } from 'react';

interface PhotoUploaderProps {
    endpoint: string;
    onUploadComplete: () => void;
    additionalMetadata?: Record<string, any>;
    onError?: (error: string) => void;
}

const PhotoUploader = forwardRef<FileUploaderRef, PhotoUploaderProps>((props, ref) => {
    return (
        <BaseFileUploader
            {...props}
            ref={ref}
            allowedFileTypes={['.jpg', '.jpeg', '.png']}
            fieldName="photo"
            thumbnailGenerator={true}
        />
    );
});

export default PhotoUploader;