import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {styled} from '@mui/system';
import './InspectionList.css';
import {format, parseISO} from 'date-fns';
import {host} from './tableConfig'
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {createAxiosWrapper} from "./Util";

interface Inspection {
    id: number;
    timestamp: string;
    list_id: string;
    values: { [key: string]: number }[];
}

interface ListItem {
    id: number;
    name: string;
    list_id: string;
}

interface InspectionListProps {
    listId: number;
}

const ColorSquare = styled(Box)<{ status: number }>`
    width: 16px;
    height: 16px;
    background-color: ${({status}) => {
        switch (status) {
            case 0:
                return 'red';
            case 1:
                return 'gold';
            case 2:
                return 'mediumseagreen';
            default:
                return 'transparent';
        }
    }};
`;

const StyledTable = styled(Table)`
    margin-top: 40px;
    width: auto;
`;

const StyledTableCell = styled(TableCell)`
    max-width: 20px;
    padding: 4px;
    border-bottom: none;
`;

const FirstColumnTableCell = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'isMobile'
})<{ isMobile: boolean }>`
    ${({isMobile}) => isMobile ? `
        max-width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    ` : `
        min-width: 200px;
    `}
    padding: 4px;
    border-bottom: none;
    font-size: .9rem;
`;

const RotatedHeader = styled(Typography)`
    transform: rotate(90deg);
    white-space: nowrap;
    margin-top: -45px;
    max-width: 20px;
`;

const StyledTableRow = styled(TableRow)`
    height: 24px;
`;

const InspectionList: React.FC<InspectionListProps> = ({listId}) => {
    const [inspections, setInspections] = useState<Inspection[]>([]);
    const [listItems, setListItems] = useState<ListItem[]>([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosWrapper({url: `${host}/inspection-list/${listId}`});
                const {inspection, list} = response.data.data;
                setInspections(inspection);
                setListItems(list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [listId]);

    const getItemName = (id: number) => {
        const item = listItems.find(item => item.id === id);
        return item ? item.name : 'Unknown';
    };

    const uniqueKeys = Array.from(new Set(inspections.flatMap(inspection => inspection.values.flatMap(value => Object.keys(value).map(Number)))));

    const renderFirstColumnCell = (key: number) => {
        const name = getItemName(key);
        if (isMobile) {
            return (
                <Tooltip title={name} arrow placement="right">
                    <span>{name}</span>
                </Tooltip>
            );
        }
        return name;
    };

    return (
        <Box
            sx={{
                //border: '1px solid #e0e0e0',
                borderRadius: '4px',
                //boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                //padding: '16px',
                backgroundColor: '#ffffff',
                overflow: 'auto',
                paddingBottom: '10px'
            }}
            className="inspection-list"
        >
            <StyledTable>
                <TableHead>
                    <StyledTableRow>
                        <FirstColumnTableCell isMobile={isMobile}></FirstColumnTableCell>
                        {inspections.map(inspection => (
                            <StyledTableCell key={inspection.id} className="table-header-cell">
                                <RotatedHeader variant="body2">
                                    {format(parseISO(inspection.timestamp), 'MM-dd-yy')}
                                </RotatedHeader>
                            </StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {uniqueKeys.map(key => {
                        const itemName = getItemName(key);
                        if (itemName !== 'Unknown') {
                            return (
                                <StyledTableRow key={key}>
                                    <FirstColumnTableCell isMobile={isMobile}>
                                        {renderFirstColumnCell(key)}
                                    </FirstColumnTableCell>
                                    {inspections.map(inspection => (
                                        <StyledTableCell key={inspection.id}>
                                            <ColorSquare
                                                status={inspection.values.find(value => value[key.toString()] !== undefined)?.[key.toString()] ?? -1}/>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            );
                        }
                        return null;
                    })}
                </TableBody>
            </StyledTable>
        </Box>
    );
};

export default InspectionList;