import React, { useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    CircularProgress,
} from '@mui/material';
import { host } from "../tableConfig";
import MultiPhotoUploader from '../MultiPhotoUploader';
import useUserStore from "../store/userStore";

interface AddPhotosDialogProps {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
    albums: any[];
}

const AddPhotosDialog: React.FC<AddPhotosDialogProps> = ({
                                                             open,
                                                             onClose,
                                                             onComplete,
                                                             albums,
                                                         }) => {
    const [selectedAlbum, setSelectedAlbum] = useState('');
    const [notes, setNotes] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const uploaderRef = useRef<{ startUpload: () => Promise<void>; reset: () => void; } | null>(null);
    const { user } = useUserStore();
    const clientId = user.clientId;

    const handleStartUpload = async () => {
        if (!selectedAlbum) return;

        setIsUploading(true);
        if (uploaderRef.current) {
            await uploaderRef.current.startUpload();
        }
        setIsUploading(false);
    };

    const handleClose = () => {
        if (!isUploading) {
            setSelectedAlbum('');
            setNotes('');
            if (uploaderRef.current) {
                uploaderRef.current.reset();
            }
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '80vh'
                }
            }}
        >
            <DialogTitle>Add Photos</DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl fullWidth required>
                        <InputLabel id="album-select-label">Select Album</InputLabel>
                        <Select
                            labelId="album-select-label"
                            value={selectedAlbum}
                            label="Select Album"
                            onChange={(e) => setSelectedAlbum(e.target.value)}
                            required
                        >
                            {albums.map((album) => (
                                <MenuItem key={album.id} value={album.id}>
                                    {album.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        variant="outlined"
                        placeholder="Add notes about these photos..."
                    />

                    <MultiPhotoUploader
                        endpoint={`${host}/upload`}
                        onUploadComplete={onComplete}
                        additionalMetadata={{
                            albumId: selectedAlbum,
                            clientId: clientId,
                            notes: notes // Added notes to metadata
                        }}
                        onError={(error) => console.error(error)}
                        ref={uploaderRef}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{marginRight: '20px', marginLeft: '20px'}}>
                <Box sx={{ position: 'relative', display: 'flex', gap: 1 }}>
                    {isUploading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: -40,
                                marginTop: '-12px',
                            }}
                        />
                    )}
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        disabled={isUploading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleStartUpload}
                        disabled={isUploading || !selectedAlbum}
                    >
                        Upload
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AddPhotosDialog;