import React, {useState, useEffect, useRef} from 'react';
import { Close as DeleteIcon } from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import MultiPhotoUploader, { MultiPhotoUploaderRef } from '../MultiPhotoUploader';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Select, MenuItem, InputLabel, FormControl, Button, IconButton,
    useMediaQuery, useTheme, Typography, Box, CircularProgress
} from '@mui/material';
import { ScheduledProcess, Ingredient, IngredientOption, MetricOption } from '../types';
import ReadOnlyScheduledProcess from './ReadOnlyScheduledProcess';
import {host} from '../tableConfig'
import {createAxiosWrapper} from "../Util";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";

interface EditScheduledProcessDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (process: ScheduledProcess) => Promise<boolean>;
    processId: number;
    clientName: string;
    processPhotos?: any[];
}

interface PackagingOption {
    id: number;
    name: string;
}

interface ProductOption {
    id: number;
    name: string;
}

const EditScheduledProcessDialog: React.FC<EditScheduledProcessDialogProps> = ({ open, onClose, onSave, processId }) => {
    const [name, setName] = useState('');
    const [clientId, setClientId] = useState<number | ''>('');
    const [process, setProcess] = useState('');
    const [notes, setNotes] = useState('');
    const [qualityParams, setQualityParams] = useState('');
    const [setup, setSetup] = useState('');
    const [clientName, setClientName] = useState('');
    const [packagingSpecs, setPackagingSpecs] = useState('');
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [ingredientList, setIngredientList] = useState<IngredientOption[]>([]);
    const [metrics, setMetrics] = useState<MetricOption[]>([]);
    const [clients, setClients] = useState<{ id: number; name: string; }[]>([]);
    const [packaging, setPackaging] = useState<{ id: number | null, count: number | null }[]>([]);
    const [packagingList, setPackagingList] = useState<PackagingOption[]>([]);
    const [products, setProducts] = useState<{ id: number | null, count: number | null }[]>([]);
    const [productList, setProductList] = useState<ProductOption[]>([]);
    const [readOnly, setReadOnly] = useState(true);
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);
    const [photos, setPhotos] = useState<any[]>([]); // For tracking uploaded photos
    const [isUploading, setIsUploading] = useState(false);
    const uploaderRef = useRef<MultiPhotoUploaderRef>(null);
    const [previewImage, setPreviewImage] = useState<{ url: string; name: string } | null>(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderClientName = () => (
        <Box mb={2}>
            <Typography variant="subtitle2">Client</Typography>
            <Typography variant="body1">{clientName}</Typography>
        </Box>
    );

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (open && processId !== null) {
                try {
                    const data = await fetchProcessData();
                    await fetchIngredients();
                    await fetchMetrics();
                    await fetchClients();
                    await fetchPackagingOptions();
                    await fetchProductOptions(data.clientId);
                    await fetchProcessPhotos(); // Add this
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchDataSequentially();
    }, [open, processId]);

    const handleDeletePhoto = async (photoId: number) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/process-photos/${photoId}`,
                method: 'DELETE'
            });

            if (response.data.result === 'success') {
                // Refresh the photos list
                fetchProcessPhotos();
            } else {
                console.error('Failed to delete photo:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    };

    const fetchProcessData = async () => {
        let processData:any = {}
        try {
            console.log(`Fetching process data for ID: ${processId}`); // Debugging log
            const response = await axiosWrapper({url:`${host}/scheduled-process/${processId}`});
            const data = response.data;
            console.log('Fetched process data:', data); // Debugging log
            if (data.result === 'success' && data.data) {
                const d = data.data[0];
                setName(d.name || '');
                setClientId(d.clientId || '');
                setProcess(d.process || '');
                setNotes(d.notes || '');
                setClientName(d.clientName || '');
                setQualityParams(d.qualityParams || '');
                setSetup(d.setup || '');
                setPackagingSpecs(d.packagingSpecs || '');
                setIngredients(d.ingredients || []);
                setPackaging(d.packaging || []);
                setProducts(d.products || []);
                processData = d;
            } else {
                console.error('Failed to fetch process data:', data.message);
                setIngredients([]); // Ensure it's always an array
                setPackaging([]);   // Ensure it's always an array
                setProducts([]);    // Ensure it's always an array
            }
        } catch (error) {
            console.error('Error fetching process data:', error);
            setIngredients([]); // Set to empty array on error
            setPackaging([]);   // Set to empty array on error
            setProducts([]);    // Set to empty array on error
        }
        return processData;
    };

    const fetchProcessPhotos = async () => {
        try {
            const response = await axiosWrapper({
                url: `${host}/getSqlResults/list_process_photos?filter=${processId}`
            });
            const data = response.data;
            if (data.result === 'success') {
                setPhotos(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching process photos:', error);
        }
    };

    const fetchIngredients = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/ingredient`});
            const data = response.data;
            if (data.result === 'success') {
                setIngredientList(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching ingredients:', error);
        }
    };

    const fetchMetrics = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/getSQLResults/metrics`});
            const data = response.data;
            if (data.result === 'success') {
                setMetrics(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching metrics:', error);
        }
    };

    const fetchClients = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/client`});
            const data = response.data;
            if (data.result === 'success') {
                setClients(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const fetchPackagingOptions = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/packaging`});
            const data = response.data;
            if (data.result === 'success') {
                setPackagingList(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching packaging options:', error);
        }
    };

    const fetchProductOptions = async (client: number) => {
        try {
            const response = await axiosWrapper({url:`${host}/product?clientId=${client}`});
            const data = response.data;
            if (data.result === 'success') {
                setProductList(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching product options:', error);
        }
    };

    const handleAddIngredient = () => {
        setIngredients([...ingredients, { id: null, name: '', metric: null, count: null }]);
    };

    const handleIngredientChange = (index: number, field: keyof Ingredient, value: string | number) => {
        const updatedIngredients = ingredients.map((ingredient, i) => {
            if (i === index) {
                switch (field) {
                    case 'id':
                        const selectedIngredient = ingredientList.find(item => item.id === Number(value));
                        return {
                            ...ingredient,
                            id: value === '' ? null : Number(value),
                            name: selectedIngredient ? selectedIngredient.name : ''
                        };
                    case 'metric':
                        return { ...ingredient, metric: value === '' ? null : Number(value) };
                    case 'count':
                        return { ...ingredient, count: value === '' ? null : Number(value) };
                    default:
                        return ingredient;
                }
            }
            return ingredient;
        });
        setIngredients(updatedIngredients);
    };

    const handleAddPackaging = () => {
        setPackaging([...packaging, { id: null, count: null }]);
    };

    const handlePackagingChange = (index: number, field: 'id' | 'count', value: string | number) => {
        const updatedPackaging = packaging.map((pack, i) => {
            if (i === index) {
                return {
                    ...pack,
                    [field]: value === '' ? null : Number(value)
                };
            }
            return pack;
        });
        setPackaging(updatedPackaging);
    };

    const handleAddProduct = () => {
        setProducts([...products, { id: null, count: null }]);
    };

    const handleProductChange = (index: number, field: 'id' | 'count', value: string | number) => {
        const updatedProducts = products.map((product, i) => {
            if (i === index) {
                return {
                    ...product,
                    [field]: value === '' ? null : Number(value)
                };
            }
            return product;
        });
        setProducts(updatedProducts);
    };

    const handleStartUpload = async () => {
        if (uploaderRef.current) {
            setIsUploading(true);
            try {
                await uploaderRef.current.startUpload();
            } catch (error) {
                console.error('Upload error:', error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const handleSave = async () => {
        try {
            // First, save the process data
            const updatedProcess: ScheduledProcess = {
                id: processId,
                name,
                clientId: clientId as number,
                process,
                notes,
                qualityParams,
                setup,
                packagingSpecs,
                ingredients,
                packaging,
                products
            };

            const success = await onSave(updatedProcess);

            if (success) {
                // If there are any pending photo uploads, process them
                if (uploaderRef.current) {
                    try {
                        await uploaderRef.current.startUpload();
                    } catch (error) {
                        console.error('Error uploading photos:', error);
                        // You might want to show an error message to the user here
                    }
                }

                onClose();
                setReadOnly(true);
            }
        } catch (error) {
            console.error('Error saving process:', error);
        }
    };

    return (
        <>
            {readOnly ? (
                <ReadOnlyScheduledProcess
                    open={open}
                    onClose={onClose}
                    onEdit={() => setReadOnly(false)}
                    process={{
                        id: processId,
                        name,
                        clientId: clientId as number,
                        process,
                        clientName,
                        qualityParams,
                        setup,
                        packagingSpecs,
                        notes,
                        ingredients,
                        packaging,
                        products
                    }}
                    qualityParams={qualityParams}
                    setup={setup}
                    packagingSpecs={packagingSpecs}
                    ingredientList={ingredientList}
                    packagingList={packagingList}
                    productList={productList}
                    clients={clients}
                    metrics={metrics}
                    showButtons={true}
                    photos={photos}
                />
            ) : (
                <Dialog
                    open={open}
                    onClose={onClose}
                    fullScreen={fullScreen}
                    maxWidth="md"
                    fullWidth
                    PaperProps={{
                        style: {
                            margin: fullScreen ? '0' : '32px',
                            width: fullScreen ? '100%' : 'calc(100% - 64px)',
                            maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)',
                        },
                    }}
                >
                    <DialogTitle>Edit Scheduled Process</DialogTitle>
                    <DialogContent>
                        {renderClientName()}
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {/* Ingredients Section */}
                        <Typography variant="h6" sx={{ marginTop: '10px' }}>
                            Ingredients
                        </Typography>
                        {ingredients.map((ingredient, index) => (
                            <div key={index} style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel>Ingredient</InputLabel>
                                    <Select
                                        value={ingredient.id === null ? '' : ingredient.id}
                                        onChange={(e) => handleIngredientChange(index, 'id', e.target.value as number)}
                                    >
                                        {ingredientList.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl style={{ width: '190px', maxWidth: '190px' }}>
                                    <InputLabel>Metric</InputLabel>
                                    <Select
                                        value={ingredient.metric === null ? '' : ingredient.metric}
                                        onChange={(e) => handleIngredientChange(index, 'metric', e.target.value as number)}
                                    >
                                        {metrics.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Count"
                                    type="number"
                                    value={ingredient.count === null ? '' : ingredient.count}
                                    onChange={(e) => handleIngredientChange(index, 'count', e.target.value)}
                                    inputProps={{ step: "any" }}
                                    sx={{marginBottom: '10px'}}
                                />
                            </div>
                        ))}
                        <Button  variant="contained" onClick={handleAddIngredient} >
                            Add Ingredient
                        </Button>

                        {/* Packaging Section */}
                        <Typography variant="h6" sx={{ marginTop: '10px' }}>
                            Packaging
                        </Typography>
                        {packaging.map((pack, index) => (
                            <div key={index} style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel>Packaging</InputLabel>
                                    <Select
                                        value={pack.id === null ? '' : pack.id}
                                        onChange={(e) => handlePackagingChange(index, 'id', e.target.value as number)}
                                    >
                                        {packagingList.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Count"
                                    type="number"
                                    value={pack.count === null ? '' : pack.count}
                                    onChange={(e) => handlePackagingChange(index, 'count', e.target.value)}
                                    inputProps={{ step: "any" }}
                                />
                            </div>
                        ))}
                        <Button  variant="contained" onClick={handleAddPackaging} >
                            Add Packaging
                        </Button>

                        {/* Product Section */}
                        <Typography variant="h6" sx={{ marginTop: '10px' }}>
                            Products
                        </Typography>
                        {products.map((product, index) => (
                            <div key={index} style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel>Product</InputLabel>
                                    <Select
                                        value={product.id === null ? '' : product.id}
                                        onChange={(e) => handleProductChange(index, 'id', e.target.value as number)}
                                    >
                                        {productList.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Count"
                                    type="number"
                                    value={product.count === null ? '' : product.count}
                                    onChange={(e) => handleProductChange(index, 'count', e.target.value)}
                                    inputProps={{ step: "any" }}
                                />
                            </div>
                        ))}
                        <Button  variant="contained" onClick={handleAddProduct} >
                            Add Product
                        </Button>

                        <TextField
                            sx={{ marginTop: '30px' }}
                            margin="dense"
                            label="Quality Parameters For Finished Product"
                            fullWidth
                            multiline
                            rows={4}
                            value={qualityParams}
                            onChange={(e) => setQualityParams(e.target.value)}
                        />
                        <TextField
                            sx={{ marginTop: '10px' }}
                            margin="dense"
                            label="Setup"
                            fullWidth
                            multiline
                            rows={4}
                            value={setup}
                            onChange={(e) => setSetup(e.target.value)}
                        />
                        <TextField
                            sx={{ marginTop: '10px' }}
                            margin="dense"
                            label="Packaging Specifications"
                            fullWidth
                            multiline
                            rows={4}
                            value={packagingSpecs}
                            onChange={(e) => setPackagingSpecs(e.target.value)}
                        />
                        <TextField
                            sx={{ marginTop: '10px' }}
                            margin="dense"
                            label="Process Steps"
                            fullWidth
                            multiline
                            rows={4}
                            value={process}
                            onChange={(e) => setProcess(e.target.value)}
                        />
                        <TextField
                            sx={{ marginTop: '10px' }}
                            margin="dense"
                            label="Notes"
                            fullWidth
                            multiline
                            rows={4}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                        {/* Add the photos section */}
                        <Typography variant="h6" sx={{ marginTop: '20px', marginBottom: '10px' }}>
                            Photos
                        </Typography>

                        {/* Display existing photos if needed */}
                        {photos.length > 0 && (
                            <>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginBottom: 2 }}>
                                    {photos.map((photo) => (
                                        <Box
                                            key={photo.id}
                                            sx={{
                                                position: 'relative',
                                                '&:hover .delete-button': {
                                                    opacity: 1
                                                }
                                            }}
                                        >
                                            <img
                                                src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/' + photo.url}
                                                alt={photo.name}
                                                style={{
                                                    width: 100,
                                                    height: 100,
                                                    objectFit: 'cover',
                                                    borderRadius: 4,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => setPreviewImage(photo)}
                                            />
                                            <IconButton
                                                className="delete-button"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent opening preview when clicking delete
                                                    handleDeletePhoto(photo.id);
                                                }}
                                                sx={{
                                                    position: 'absolute',
                                                    top: -8,
                                                    right: -8,
                                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                    color: '#fff',
                                                    opacity: 0,
                                                    transition: 'opacity 0.2s',
                                                    '&:hover': {
                                                        backgroundColor: '#000',
                                                    },
                                                    padding: '4px',
                                                    '& svg': {
                                                        fontSize: '16px'
                                                    }
                                                }}
                                                size="small"
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>

                                {/* Image Preview Modal */}
                                <Dialog
                                    open={Boolean(previewImage)}
                                    onClose={() => setPreviewImage(null)}
                                    maxWidth={false}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            position: 'relative'
                                        }
                                    }}
                                >
                                    <DialogContent sx={{ padding: 0, position: 'relative' }}>
                                        <IconButton
                                            onClick={() => setPreviewImage(null)}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                color: '#fff',
                                                '&:hover': {
                                                    backgroundColor: '#000',
                                                },
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        {previewImage && (
                                            <img
                                                src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/uploads/' + previewImage.url.replace('-sm-', '-')} // Use original image URL
                                                alt={previewImage.name}
                                                style={{
                                                    maxHeight: 'calc(100vh - 64px)',
                                                    maxWidth: '100%',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        )}
                                    </DialogContent>
                                </Dialog>
                            </>
                        )}

                        {/* Add the MultiPhotoUploader */}
                        <MultiPhotoUploader
                            ref={uploaderRef}
                            endpoint={`${host}/process-photos`}
                            onUploadComplete={fetchProcessPhotos}
                            additionalMetadata={{
                                processId: processId,
                                clientId: clientId
                            }}
                            onError={(error) => console.error('Upload error:', error)}
                        />
                    </DialogContent>
                    <DialogActions sx={{marginRight: '20px', marginLeft: '20px'}}>
                        <Box sx={{ position: 'relative', display: 'flex', gap: 1 }}>
                            {isUploading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: -40,
                                        marginTop: '-12px',
                                    }}
                                />
                            )}
                            <Button variant="contained" color="secondary"  onClick={() => {setReadOnly(true); onClose()}}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleStartUpload}>
                                Upload Photos
                            </Button>
                            <Button variant="contained" onClick={handleSave}>
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default EditScheduledProcessDialog;
