import React, {useState, useEffect, useRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Typography,
    Box,
    CircularProgress,
    Select,
    MenuItem,
    InputLabel,
    SelectChangeEvent,
    useTheme,
    useMediaQuery,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Alert
} from '@mui/material';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {Camera, Delete as DeleteIcon, Download as DownloadIcon} from '@mui/icons-material';
import {Column} from './TablePanel';
import {createAxiosWrapper, formatCurrency} from "./Util";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {host} from './tableConfig';
import DocumentUploader from "./DocumentUploader";
import {FileUploaderRef} from './BaseFileUploader';

interface Item {
    id: number;

    [key: string]: any;
}

interface Document {
    id: number;
    url: string;
    name: string;
    timestamp: string;
    doc_type: number;
    doc_type_name: string;
}

interface SelectOption {
    id: number | string;

    [key: string]: any;
}

interface ItemDialogProps {
    open: boolean;
    onClose: () => void;
    onSave?: (item: Omit<Item, 'id'>) => Promise<void>;
    item?: Item;
    mode: 'add' | 'view' | 'edit';
    onModeChange?: (newMode: 'add' | 'view' | 'edit') => void;
    columns: readonly Column[];
    itemTitle: string;
    clientId: number | string;
    clientName: string;
    docType?: string;
    ro?: boolean;
}

type Order = 'asc' | 'desc';

function nullToEmptyStringInPlace(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null) {
            obj[key] = '';
        }
    });
    return obj;
}

const sortDocuments = (
    docs: Document[],
    orderBy: keyof Document,
    order: 'asc' | 'desc'
): Document[] => {
    return [...docs].sort((a, b) => {
        const isAsc = order === 'asc';
        if (orderBy === 'timestamp') {
            return (isAsc ? 1 : -1) *
                (new Date(a[orderBy]).getTime() - new Date(b[orderBy]).getTime());
        }
        const aStr = String(a[orderBy]);
        const bStr = String(b[orderBy]);
        return (isAsc ? 1 : -1) * aStr.localeCompare(bStr);
    });
};

const ItemDialogPlusDocuments: React.FC<ItemDialogProps> = ({
                                                                open,
                                                                onClose,
                                                                onSave,
                                                                item,
                                                                mode,
                                                                onModeChange,
                                                                columns,
                                                                itemTitle,
                                                                clientId,
                                                                clientName,
                                                                docType = 'Document',
                                                                ro
                                                            }) => {
    const [formData, setFormData] = useState<Omit<Item, 'id'>>({});
    const [isSaving, setIsSaving] = useState(false);
    const [selectOptions, setSelectOptions] = useState<{ [key: string]: SelectOption[] }>({});
    const [documents, setDocuments] = useState<Document[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [orderBy, setOrderBy] = useState<keyof Document>('name');
    const [order, setOrder] = useState<Order>('asc');
    const uploaderRef = useRef<FileUploaderRef>(null);
    const [categories, setCategories] = useState<Array<{ id: number; name: string }>>([]);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<number | ''>('');

    const {getToken} = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isViewMode = mode === 'view';

    useEffect(() => {
        if (item) {
            const cleanedItem = nullToEmptyStringInPlace({...item});
            setFormData(cleanedItem);
        } else {
            const newFormData: { [key: string]: any } = {};
            columns.forEach(column => {
                newFormData[column.id] = column.type === 'radio' ? (column.options?.[0] || '') : '';
            });
            setFormData(newFormData);
        }

        columns.forEach(column => {
            if (column.type === 'select' && column.endpoint) {
                fetchSelectOptions(column.id, column.byClient, column.endpoint);
            }
        });
    }, [item, open, columns]);

    useEffect(() => {
        if (item?.id) {
            fetchItemDocuments();
        }
    }, [item?.id]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosWrapper({
                    url: `${host}/getSQLResults/list_document_categories`
                });
                if (response.data.result === 'success') {
                    setCategories(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const fetchItemDocuments = async () => {
        if (!item?.id) return;
        try {
            const response = await axiosWrapper({
                url: `${host}/item-docs?fk=${item.id}&ft=${itemTitle}`
            });
            const data = response.data;
            if (data.result === 'success') {
                setDocuments(data.data || []);
            }
        } catch (error) {
            console.error(`Error fetching item ${docType}:`, error);
        }
    };

    const handleDeleteDocument = async (documentId: number) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/item-docs/${documentId}`,
                method: 'DELETE'
            });

            if (response.data.result === 'success') {
                fetchItemDocuments();
            } else {
                console.error('Failed to delete document:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    };

    const handleDownload = async (documentUrl: string, fileName: string) => {
        try {
            const s3Path = documentUrl;

            const response = await axiosWrapper({
                url: `${host}/download-document`,
                method: 'POST',
                data: {s3Path},
                responseType: 'blob'
            });

            const blob = new Blob([response.data], {
                type: response.headers['content-type'] || 'application/octet-stream'
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download error:', error);
        }
    };

    const handleStartUpload = () => {
        setCategoryDialogOpen(true);
    };

    const handleConfirmUpload = async () => {
        if (!selectedCategory) return;

        if (uploaderRef.current && item?.id) {
            setIsUploading(true);
            setCategoryDialogOpen(false);
            try {
                const uploadEndpoint = `${host}/item-docs?ft=${itemTitle}&fk=${item.id}&categoryId=${selectedCategory}`;
                uploaderRef.current.setEndpoint(uploadEndpoint);

                await uploaderRef.current.startUpload();
                fetchItemDocuments();
                setSelectedCategory('');
            } catch (error) {
                console.error('Upload error:', error);
            } finally {
                setIsUploading(false);
            }
        }
    };

    const handleSort = (property: keyof Document) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const fetchSelectOptions = async (columnId: string, byClient: boolean | undefined, endpoint: string) => {
        try {
            let url = endpoint;
            if (byClient) {
                url = `${endpoint}?clientId=${clientId || 0}`;
            }
            const response = await axiosWrapper({url});
            if (response.data.result === 'success' && Array.isArray(response.data.data)) {
                setSelectOptions(prev => ({
                    ...prev,
                    [columnId]: response.data.data
                }));
            }
        } catch (error) {
            console.error(`Error fetching options for ${columnId}:`, error);
        }
    };

    const formatDate = (date: string): string => {
        if (!date) return 'Not set';
        const dateObj = new Date(date);
        if (isNaN(dateObj.getTime())) return 'Not set';
        return dateObj.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const handleDateChange = (columnId: string, newValue: dayjs.Dayjs | null) => {
        setFormData(prev => ({
            ...prev,
            [columnId]: newValue ? newValue.format('YYYY-MM-DD') : ''
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name as string]: value}));
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleSave = async () => {
        if (!onSave) return;
        setIsSaving(true);
        try {
            await onSave(formData);
            onModeChange?.('view');
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
        } finally {
            setIsSaving(false);
        }
    };

    const renderClientName = () => (
        <Box mb={2}>
            <Typography variant="subtitle2">Client</Typography>
            <Typography variant="body1">{clientName}</Typography>
        </Box>
    );

    const renderField = (column: Column, value: string | number) => {
        let displayValue: string | number = value;

        if (column.dataType === 'date') {
            displayValue = formatDate(value as string);
        } else if (column.id === 'price' && value) {
            displayValue = formatCurrency(value);
        } else if (value === '' || value === undefined || value === null) {
            displayValue = 'Not set';
        } else if (column.type === 'radio' && column.options) {
            displayValue = column.options[Number(value)] || value;
        } else if (column.type === 'select' && selectOptions[column.id]) {
            const option = selectOptions[column.id].find(opt => opt.id.toString() === value.toString());
            displayValue = option ? option.brand_name || option.name || value : value;
        }

        return (
            <Box key={column.id} mb={2}>
                <Typography variant="subtitle2">{column.label}</Typography>
                <Typography variant="body1">{displayValue}</Typography>
            </Box>
        );
    };

    const renderInput = (column: Column) => {
        if (column.dataType === 'date') {
            return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={column.label}
                        value={formData[column.id] ? dayjs(formData[column.id]) : null}
                        onChange={(newValue) => handleDateChange(column.id, newValue)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                margin: "normal"
                            }
                        }}
                    />
                </LocalizationProvider>
            );
        } else if (column.type === 'radio' && column.options) {
            return (
                <FormControl component="fieldset" margin="normal" key={column.id}>
                    <FormLabel component="legend">{column.label}</FormLabel>
                    <RadioGroup
                        aria-label={column.id}
                        name={column.id}
                        value={formData[column.id]?.toString() || ''}
                        onChange={handleRadioChange}
                    >
                        {column.options.map((option: any, index: any) => (
                            <FormControlLabel key={option} value={index.toString()} control={<Radio/>} label={option}/>
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        } else if (column.type === 'select') {
            const currentValue = formData[column.id];
            const options = selectOptions[column.id] || [];
            const hasMatchingOption = options.some(opt => opt.id.toString() === currentValue?.toString());

            return (
                <FormControl fullWidth margin="normal" key={column.id}>
                    <InputLabel id={`${column.id}-label`}>{column.label}</InputLabel>
                    <Select
                        labelId={`${column.id}-label`}
                        id={column.id}
                        name={column.id}
                        value={hasMatchingOption ? currentValue : ''}
                        onChange={handleSelectChange}
                        label={column.label}
                    >
                        {!hasMatchingOption && currentValue && (
                            <MenuItem value={currentValue}>
                                {currentValue} (Current Value)
                            </MenuItem>
                        )}
                        {options.map((option: SelectOption) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.brand_name || option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else {
            return (
                <TextField
                    key={column.id}
                    name={column.id}
                    label={column.label}
                    value={formData[column.id] || ''}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    multiline={column.rows !== undefined}
                    rows={column.rows}
                />
            );
        }
    };

    const renderPhotoNote = () => (
        <Alert
            icon={<Camera className="h-5 w-5"/>}
            severity="info"
            sx={{
                mt: 2,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '& .MuiAlert-icon': {
                    mr: 1
                }
            }}
        >
            Document files can be added after saving this record.
        </Alert>
    );

    const renderDocumentsSection = () => (
        <>
            <Typography variant="h6" sx={{marginTop: '20px', marginBottom: '10px'}}>
                Documents
            </Typography>

            {mode === 'edit' && item?.id && (
                <DocumentUploader
                    ref={uploaderRef}
                    endpoint={`${host}/item-docs?ft=${itemTitle}&fk=${item.id}`}
                    onUploadComplete={fetchItemDocuments}
                    additionalMetadata={{
                        itemId: item.id,
                        clientId
                    }}
                    onError={(error) => console.error('Upload error:', error)}
                />
            )}

            <TableContainer component={Paper} sx={{mt: 2}}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleSort('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'doc_type_name'}
                                    direction={orderBy === 'doc_type_name' ? order : 'asc'}
                                    onClick={() => handleSort('doc_type_name')}
                                >
                                    Type
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography color="textSecondary">
                                        No documents uploaded yet
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortDocuments(documents, orderBy, order).map((doc) => (
                                <TableRow key={doc.id}>
                                    <TableCell>{doc.name}</TableCell>
                                    <TableCell>
                                        {doc.doc_type_name}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDownload(doc.url, doc.name)}
                                            title="Download document"
                                        >
                                            <DownloadIcon/>
                                        </IconButton>
                                        {mode === 'edit' && (
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDeleteDocument(doc.id)}
                                                title="Delete document"
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={fullScreen}
            PaperProps={{
                sx: {
                    m: fullScreen ? 0 : 2,
                    height: fullScreen ? '100%' : 'auto',
                    maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)'
                }
            }}
        >
            <DialogTitle>
                {isViewMode ? `${itemTitle} Details` : (mode === 'edit' ? `Edit ${itemTitle}` : `Add ${itemTitle}`)}
            </DialogTitle>
            <DialogContent>
                {isViewMode ? (
                    <>
                        {renderClientName()}
                        {columns.map(column => renderField(column, formData[column.id]))}
                        {renderDocumentsSection()}
                    </>
                ) : (
                    <>
                        {renderClientName()}
                        {columns.map(column => renderInput(column))}
                        {mode === 'edit' && renderDocumentsSection()}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box sx={{position: 'relative', display: 'flex', gap: 2, margin: '20px'}}>
                    {isUploading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: -40,
                                marginTop: '-12px',
                            }}
                        />
                    )}
                    <Button variant="contained" onClick={onClose}>
                        {isViewMode ? 'Close' : 'Cancel'}
                    </Button>
                    {mode === 'edit' && (
                        <Button
                            onClick={handleStartUpload}
                            variant="contained"
                            disabled={isUploading}
                        >
                            {isUploading ? 'Uploading...' : `Upload ${docType}`}
                        </Button>
                    )}
                    {isViewMode && onSave && !ro && <Button variant="contained" onClick={() => onModeChange?.('edit')}>Edit</Button>}
                    {!isViewMode && onSave && (
                        <Button variant="contained" onClick={handleSave} disabled={isSaving}>
                            {isSaving ? <CircularProgress size={24}/> : 'Save'}
                        </Button>
                    )}
                </Box>
            </DialogActions>
            {mode === 'add' && renderPhotoNote()}
            <Dialog open={categoryDialogOpen} onClose={() => setCategoryDialogOpen(false)}>
                <DialogTitle>Select Document Category</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{mt: 2}}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value as number)}
                            label="Category"
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => setCategoryDialogOpen(false)}>Cancel</Button>
                    <Button
                        onClick={handleConfirmUpload}
                        disabled={!selectedCategory}
                        variant="contained"
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
};

export default ItemDialogPlusDocuments;