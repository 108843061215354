import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { MenuItem } from './menuItems';

interface MainMenuProps {
    mainMenuItems: MenuItem[];
    handleMenuAction: (title: string) => void;
}

const MainMenu: React.FC<MainMenuProps> = ({ mainMenuItems, handleMenuAction }) => {
    return (
        <Box sx={{
            maxWidth: {
                xs: '100%',
                md: '1600px'
            },
            mx: 'auto',
            px: {
                xs: 2,
                md: 4
            },
            py: 3
        }}>
            <Grid container spacing={2}>
                {mainMenuItems.map((item, index) => (
                    <Grid item xs={6} md={3} key={index}>
                        <Button
                            onClick={() => handleMenuAction(item.title)}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: {
                                    xs: '90px',
                                    md: '100px'
                                },
                                width: '100%',
                                backgroundColor: 'white',
                                borderRadius: 2,
                                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                                transition: 'all 0.2s',
                                '&:hover': {
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                    transform: 'translateY(-2px)',
                                    backgroundColor: 'white'
                                }
                            }}
                        >
                            <Box sx={{
                                color: 'text.primary',
                                mb: 0.5,
                                '& > svg': {
                                    width: 30,
                                    height: 30,
                                    strokeWidth: 2
                                }
                            }}>
                                {item.icon}
                            </Box>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: 'text.secondary',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: 1.2
                                }}
                            >
                                {item.title}
                            </Typography>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MainMenu;