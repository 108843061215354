import { create } from 'zustand';

interface User {
    email: string;
    given_name: string;
    family_name: string;
    organization: string;
    clientId: number;
    clientName: string;
    clients: any[];
    facility: boolean;
    facility_num: number;
    facility_name: string;
    copacker: number;
    copacker_name: string;
}

interface UserStore {
    user: User;
    setUser: (userData: Partial<User>) => void;
    resetUser: () => void;
    getGravatarUrl: () => Promise<string>;
}

const useUserStore = create<UserStore>()((set, get) => ({
    user: {
        email: '',
        given_name: '',
        family_name: '',
        organization: '',
        clientId: 0,
        facility: false,
        facility_num: 0,
        facility_name: '',
        copacker: 0,
        copacker_name: '',
        clientName: '',
        clients: []
    },
    setUser: (userData) => set((state) => ({ user: { ...state.user, ...userData } })),
    resetUser: () => set({ user:
            { email: '', given_name: '', family_name: '', organization: '', clientId: 0, clientName: '', clients: [], facility: false, facility_num: 0, facility_name: '', copacker: 0, copacker_name: '' }
    }),
    getGravatarUrl: async () => {
        const email = get().user.email.toLowerCase().trim();
        const encoder = new TextEncoder();
        const data = encoder.encode(email);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return `https://www.gravatar.com/avatar/${hashHex}?d=mp`;
    }
}));

export default useUserStore;