// EditInvoiceDialog.tsx
import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField,
    Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Chip, Box,
    Switch, FormControlLabel, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {createAxiosWrapper, formatDate} from "./Util";
import { host } from './tableConfig';
import { Invoice, Batch } from './types';

interface EditInvoiceDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (invoice: Invoice) => Promise<boolean>;
    invoiceId: number;
    clientId: number;
    clientName: string;
}

const EditInvoiceDialog: React.FC<EditInvoiceDialogProps> = ({ open, onClose, onSave, invoiceId, clientId, clientName }) => {
    const [notes, setNotes] = useState('');
    const [selectedBatches, setSelectedBatches] = useState<Batch[]>([]);
    const [availableBatches, setAvailableBatches] = useState<Batch[]>([]);
    const [paid, setPaid] = useState(false);
    const [timestamp, setTimestamp] = useState<string>('');
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (open) {
            fetchInvoiceData();
            fetchBatches();
        }
    }, [open, invoiceId, clientId]);

    const fetchInvoiceData = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/invoice/${invoiceId}` });
            if (response.data.result === 'success') {
                const invoice = response.data.data[0];
                setNotes(invoice.notes);
                setSelectedBatches(invoice.batches);
                setPaid(invoice.paid);
                setTimestamp(invoice.timestamp);
            }
        } catch (error) {
            console.error('Error fetching invoice data:', error);
        }
    };

    const fetchBatches = async () => {
        try {
            const response = await axiosWrapper({ url: `${host}/getSQLResults/list_free_batches/${clientId}` });
            if (response.data) {
                setAvailableBatches(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching batches:', error);
        }
    };

    const handleBatchChange = (event: SelectChangeEvent<number>) => {
        const batchId = event.target.value as number;
        const selectedBatch = availableBatches.find(batch => batch.id === batchId);
        if (selectedBatch) {
            setSelectedBatches([...selectedBatches, selectedBatch]);
        }
    };

    const handleRemoveBatch = (batchId: number) => {
        setSelectedBatches(selectedBatches.filter(batch => batch.id !== batchId));
    };

    const handleSave = async () => {
        const updatedInvoice: Invoice = {
            id: invoiceId,
            clientId,
            clientName,
            batches: selectedBatches,
            notes,
            paid,
            timestamp
        };
        const success = await onSave(updatedInvoice);
        if (success) {
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={fullScreen}
            PaperProps={{
                sx: {
                    m: fullScreen ? 0 : 2,
                    height: fullScreen ? '100%' : 'auto',
                    maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)'
                }
            }}
        >
            <DialogTitle>Edit Invoice for {clientName}</DialogTitle>
            <DialogContent>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Invoice Date: {formatDate(timestamp)}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Invoice Number: {invoiceId}
                </Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Add Batch</InputLabel>
                    <Select onChange={handleBatchChange} value="">
                        {availableBatches.map((batch) => (
                            <MenuItem key={batch.id} value={batch.id}>
                                {`${batch.process_name} - ${formatDate(batch.timestamp)}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 2, mb: 2 }}>
                    {selectedBatches.map((batch) => (
                        <Chip
                            key={batch.id}
                            label={`[${batch.id}] ${batch.process_name} - ${formatDate(batch.timestamp)}`}
                            onDelete={() => handleRemoveBatch(batch.id)}
                        />
                    ))}
                </Box>
                <TextField
                    margin="normal"
                    label="Notes"
                    fullWidth
                    multiline
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
                <FormControlLabel
                    control={<Switch checked={paid} onChange={(e) => setPaid(e.target.checked)} />}
                    label="Paid"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditInvoiceDialog;