import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, IconButton, Dialog, DialogContent } from '@mui/material';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Close as CloseIcon } from '@mui/icons-material';
import { createAxiosWrapper, formatCurrency, formatItemType } from './Util';
import config from './tableConfig';
import { host } from './tableConfig';

interface ItemDetailsProps {
    itemId: string;
    itemType: 'products' | 'ingredients' | 'packaging' | 'equipment';
    isExpanded: boolean;
}

interface Photo {
    id: number;
    url: string;
    name: string;
}

const ItemDetails: React.FC<ItemDetailsProps> = ({ itemId, itemType, isExpanded }) => {
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [itemData, setItemData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectOptions, setSelectOptions] = useState<any>({});
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [previewImage, setPreviewImage] = useState<Photo | null>(null);

    useEffect(() => {
        const fetchItemDetails = async () => {
            if (!isExpanded || hasLoaded) return;

            setIsLoading(true);
            try {
                const endpoints = config[itemType]?.endpoints;
                const columns = (config[itemType]?.columns as unknown) as any[] || [];

                if (!endpoints?.get) {
                    console.error(`No endpoints found for item type: ${itemType}`);
                    return;
                }

                // Fetch main item data
                const response = await axiosWrapper({
                    url: `${endpoints.get}/${itemId}`,
                    method: 'get'
                });

                if (response.data.result === 'success') {
                    setItemData(response.data.data);
                    // Fetch photos after getting item data
                    await fetchItemPhotos(itemId);
                }

                // Fetch select options for fields that need them
                for (const column of columns) {
                    if (column.type === 'select' && column.endpoint) {
                        try {
                            let url = column.endpoint;
                            if (column.byClient && response.data.data.client) {
                                url = `${column.endpoint}?clientId=${response.data.data.client}`;
                            }
                            const optionsResponse = await axiosWrapper({ url });
                            if (optionsResponse.data.result === 'success') {
                                setSelectOptions((prev: any) => ({
                                    ...prev,
                                    [column.id]: optionsResponse.data.data
                                }));
                            }
                        } catch (error) {
                            console.error(`Error fetching options for ${column.id}:`, error);
                        }
                    }
                }

                setHasLoaded(true);
            } catch (error) {
                console.error('Error fetching item details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchItemDetails();
    }, [isExpanded, itemId, itemType, hasLoaded]);

    const fetchItemPhotos = async (id: string) => {
        try {
            const ft = formatItemType(itemType) // e.g. products to Product, TODO: should't need this
            const response = await axiosWrapper({
                url: `${host}/item-photos?fk=${id}&ft=${ft}`
            });
            if (response.data.result === 'success') {
                setPhotos(response.data.data || []);
            }
        } catch (error) {
            console.error('Error fetching photos:', error);
        }
    };

    const renderField = (column: any, value: any) => {
        let displayValue: any = value;

        if (value === '' || value === undefined || value === null) {
            displayValue = 'Not set';
        } else if (column.type === 'radio' && column.options) {
            displayValue = column.options[Number(value)] || value;
        } else if (column.type === 'select' && selectOptions[column.id]) {
            const option = selectOptions[column.id].find((opt: any) => opt.id.toString() === value.toString());
            displayValue = option ? option.brand_name || option.name || value : value;
        } else if (column.id === 'price' || column.id.includes('price')) {
            displayValue = formatCurrency(value);
        }

        return (
            <Box key={column.id} mb={2}>
                <Typography variant="subtitle2" color="textSecondary">
                    {column.label}
                </Typography>
                <Typography variant="body1">
                    {displayValue}
                </Typography>
            </Box>
        );
    };

    const renderPhotos = () => {
        if (photos.length === 0) return null;

        return (
            <Box mb={2}>
                <Typography variant="subtitle2" color="textSecondary" mb={1}>
                    Photos
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {photos.map((photo) => (
                        <Box
                            key={photo.id}
                            sx={{
                                position: 'relative',
                                cursor: 'pointer'
                            }}
                            onClick={() => setPreviewImage(photo)}
                        >
                            <img
                                src={`https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/${photo.url}`}
                                alt={photo.name}
                                style={{
                                    width: 100,
                                    height: 100,
                                    objectFit: 'cover',
                                    borderRadius: 4
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    if (!isExpanded) {
        return null;
    }

    if (isLoading) {
        return (
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    if (!itemData) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">
                    No data available
                </Typography>
            </Box>
        );
    }

    const columns = (config[itemType]?.columns as unknown) as any[] || [];

    return (
        <>
            <Box sx={{ p: 2 }}>
                {columns.map(column => renderField(column, itemData[column.id]))}
                {renderPhotos()}
            </Box>

            {/* Image Preview Dialog */}
            <Dialog
                open={Boolean(previewImage)}
                onClose={() => setPreviewImage(null)}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        position: 'relative'
                    }
                }}
            >
                <DialogContent sx={{ padding: 0, position: 'relative' }}>
                    <IconButton
                        onClick={() => setPreviewImage(null)}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#000',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {previewImage && (
                        <img
                            src={`https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/${previewImage.url.replace('-sm-', '-')}`}
                            alt={previewImage.name}
                            style={{
                                maxHeight: 'calc(100vh - 64px)',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ItemDetails;