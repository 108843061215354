import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { alpha } from '@mui/material/styles';
import BatchDialog from './BatchDialog';
import BatchDialogReadOnly from './BatchDialogReadOnly';
import { host } from '../tableConfig';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper, formatDate } from "../Util";
import { BatchData } from "../types";

interface BatchTableProps {
    newBatchAdded: number;
    onBatchUpdate: () => void;
    clientId: number | string;
}

type SortColumn = 'id' | 'close_timestamp' | 'client_name';

// Updated styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: 'bold',
        fontSize: '14px',
        padding: theme.spacing(2, 0, 2, 2),
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: alpha(theme.palette.action.hover, 0.20),
        cursor: 'pointer',
    },
}));

const ClientName = styled('span')(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    display: 'block',
    minWidth: '85px',
}));

const calculateBatchTotalCost = (batch: BatchData): number => {
    return batch.products?.reduce((batchSum, product) => {
        const toll = typeof product.toll === 'string' ? parseFloat(product.toll.replace('$', '')) : product.toll || 0;
        const count = parseFloat(product.count) ?? 0;
        return batchSum + (count * toll);
    }, 0) ?? 0;
};

const BatchTable: React.FC<BatchTableProps> = ({ newBatchAdded, onBatchUpdate, clientId }) => {
    const [batchData, setBatchData] = useState<BatchData[]>([]);
    const [orderBy, setOrderBy] = useState<SortColumn>('id');
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [readOnlyDialogOpen, setReadOnlyDialogOpen] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState<BatchData | null>(null);
    const [selectedProcess, setSelectedProcess] = useState<number | ''>('');
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const handleRowClick = async (batch: BatchData) => {
        await fetchSingleBatchData(batch.id);
        setSelectedProcess(batch.process);
        if (batch.close_timestamp) {
            setReadOnlyDialogOpen(true);
        } else {
            setDialogOpen(true);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setReadOnlyDialogOpen(false);
        setSelectedBatch(null);
    };

    const handleDialogSave = async (updatedBatch: BatchData, isDone: boolean) => {
        try {
            const response = await axiosWrapper({
                url: `${host}/update-batch`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    ...updatedBatch,
                    isDone,
                },
            });

            if (!response.data) {
                throw new Error('Failed to update batch');
            }

            onBatchUpdate();
            handleDialogClose();
        } catch (error) {
            console.error('Error updating batch:', error);
        }
    };

    useEffect(() => {
        if (clientId) {
            fetchBatchData();
        }
    }, [clientId, newBatchAdded]);

    const fetchBatchData = async () => {
        try {
            const response = await axiosWrapper({url: `${host}/get-batches-by-client/${clientId}`});
            const data = response.data;
            if (!data) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            if (data.result === "success" && Array.isArray(data.data)) {
                setBatchData(data.data);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    const fetchSingleBatchData = async (id: number) => {
        try {
            const response = await axiosWrapper({url: `${host}/get-batch/${id}`});
            const data = response.data;
            if (!data) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            if (data.result === "success" && data.data) {
                setSelectedBatch(data.data);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    const handleSort = (column: SortColumn) => {
        const isAsc = orderBy === column && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
    };

    const sortedData = [...batchData].sort((a, b) => {
        if (orderBy === 'id') {
            return order === 'asc' ? a.id - b.id : b.id - a.id;
        } else if (orderBy === 'close_timestamp') {
            if (a.close_timestamp === null) return order === 'asc' ? 1 : -1;
            if (b.close_timestamp === null) return order === 'asc' ? -1 : 1;
            return order === 'asc'
                ? a.close_timestamp.localeCompare(b.close_timestamp)
                : b.close_timestamp.localeCompare(a.close_timestamp);
        } else {
            return order === 'asc'
                ? a.client_name.localeCompare(b.client_name)
                : b.client_name.localeCompare(a.client_name);
        }
    });

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width="20px">
                                <TableSortLabel
                                    active={orderBy === 'id'}
                                    direction={orderBy === 'id' ? order : 'asc'}
                                    onClick={() => handleSort('id')}
                                >
                                    ID
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell width="60px">
                                <TableSortLabel
                                    active={orderBy === 'close_timestamp'}
                                    direction={orderBy === 'close_timestamp' ? order : 'asc'}
                                    onClick={() => handleSort('close_timestamp')}
                                >
                                    Done
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                    active={orderBy === 'client_name'}
                                    direction={orderBy === 'client_name' ? order : 'asc'}
                                    onClick={() => handleSort('client_name')}
                                >
                                    Title
                                </TableSortLabel>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => (
                            <StyledTableRow
                                key={row.id}
                                onClick={() => handleRowClick(row)}
                            >
                                <StyledTableCell>
                                    <ClientName>{formatDate(row.timestamp)}</ClientName>
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {formatDate(row.close_timestamp, 'short')}
                                    {row.close_timestamp && (
                                        <div>
                                            ${calculateBatchTotalCost(row).toFixed(2)}
                                        </div>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <ClientName>{row.client_name}</ClientName>
                                    <div>{row.process_name}</div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedBatch && !selectedBatch.close_timestamp && (
                <BatchDialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    onSave={handleDialogSave}
                    batch={selectedBatch}
                    clientId={clientId}
                    process={selectedProcess}
                />
            )}
            {selectedBatch && selectedBatch.close_timestamp && (
                <BatchDialogReadOnly
                    open={readOnlyDialogOpen}
                    onClose={handleDialogClose}
                    batch={selectedBatch}
                    process={selectedProcess}
                />
            )}
        </>
    );
};

export default BatchTable;